<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="buttonTitle"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :style="{ display: visible ? 'flex' : 'none' }"
        :title="title"
        small
      >
        <template v-if="buttonTitle.startsWith('mdi-')">
          <v-icon>
            {{ buttonTitle }}
          </v-icon>
        </template>
        <template v-else>
          {{ buttonTitle }}
        </template>
      </v-btn>
    </template>

    <v-card class="readacross dialog">
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>

        <v-container>
          <v-row align="center" justify="center">
            <v-img
            contain
            max-height="300px"
            :src="datasetId" 
            alt=""
          ></v-img>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="resultName" label="Name of result to save as" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-combobox
                v-model="selectedDesc"
                :items="selectedDescriptors"
                :return-object="false"
                :disabled="selectedDescriptors.length < 2"
                label="Neighbors by:"
              ></v-combobox>
            </v-col>
            <v-col>
              <v-combobox
                v-model="filterByDefault"
                :items="filterBy"
                :return-object="false"
                label="Classification or Regression:"
              ></v-combobox>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <v-checkbox v-model="autoNamess" label="ToxRef" />
            </v-col>
            <v-col>
              <v-select
                v-model="selectedMode"
                :item-text="modelItemText"
                :item-value="modelItemValue"
                :items="modelItems"
                :disabled="autoNamess"
                chips
                deletable-chips
                dense
                hint="Select dataset to use for read-across"
                label="Datasets"
                multiple
                persistent-hint
              />
            </v-col>
          </v-row> -->
          <v-row>
            <v-col>
              <v-text-field v-model.number="AnalogNum" type="number" label="Analog Number" />
            </v-col>
          </v-row>

          <b-alert
            :show="dismissCountDown"
            :variant="alertVariant"
            @dismissed="closeAlert()"
            @dismiss-count-down="countDownChanged"
          >
            {{ message }}
          </b-alert>

        
          <v-progress-linear v-if="uploading" indeterminate />
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false"> Cancel </v-btn>
        <v-btn :disabled="actionsDisabled" @click="onOk"> Read-Across </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { API_URL } from '@/main';
import JamlDialogBase from '@/components/JamlDialogBase';
import { replaceSpecChars } from '@/main';

export default {
  mixins: [JamlDialogBase],
  props: {
    smiles: {
      type: String,
      default: 'negativeghostrider',
    },
  },
  data: () => ({
    resultName: '',
    autoNamess: true,
    selectedMode: [],
    models: [],
    dialog: false,
    id: null,
    selectedDescriptors: [
      { text: 'ECFP', value: 'ECFP' },
      { text: 'MACCS', value: 'MACCS' },
    ],
    selectedDesc: '',
    filterBy: [
      { text: 'Classification', value: 'Classification' },
      { text: 'Regression', value: 'Regression' },
    ],
    filterByDefault: '',
    AnalogNum: 10,
    successUrl_: null,
    result: [],
  }),
  computed: {
    formTitle() {
      return 'Read Across';
    },
    datasetId() {
      return `${API_URL}rendersmiles/${this.smiles}`;
    },
    successUrl() {
      return this.successUrl_;
    },
    modelItems() {
      return this.models;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$axios.get('datasets').then((response) => {
          this.models = response.data;
        });
      }
    },
  },
  methods: {
    modelItemText(mdl) {
      return mdl.name;
    },
    classOrReg() {
      return this.filterByDefault.text;
    },
    modelItemValue(mdl) {
      return mdl._id.$oid;
    },
    onOk() {
      this.uploading = true;
      this.actionsDisabled = true;
      this.$axios
        .post('readacross', {
          result_name: replaceSpecChars(this.resultName),
          mol_id: this.smiles,
          desc: this.selectedDesc,
          filter: this.filterByDefault,
          num_neighbors: this.AnalogNum,
          dataset_for_ra: this.selectedMode,
          toxref: this.autoNamess,
          smiles: "True",
        })
        .then((response) => {
          this.onOkSuccess();
          this.result = response.data;
          this.successUrl_ = `/readacross/${this.result._id.$oid}`;
          this.message = 'Molecule submitted for Read-Across';
        })
        .catch((error) => this.onOkError(error));
    },
  },
};
</script>