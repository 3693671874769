<template>
  <v-card>

    <v-toolbar>

      <v-btn small title="Model" @click="$router.push(`/models/${item.name}`)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="ma-5">
        {{ item.name }} / {{ item.method_name }}
      </span>

      <v-spacer />

<!-- The set of icons linking the method to the file(s) and dataset that they came from -->
      <v-btn-toggle dense>

        <v-menu v-if="feature('navigate') && CONFIG.type=='assaycentral' && item.dataset.files.length > 1" offset-y>

          <template v-slot:activator="{ attrs, on }">
            <v-btn v-bind="attrs" v-on="on" small title="Go to File">
              <v-icon style="transform: scaleX(-1);">
                mdi-file-send-outline
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="file in item.dataset.files"
              :key="file.$oid"
              link
              @click="$router.push(`/files/${file.$oid}`)"
            >
              <v-list-item-title v-text="file.name" />
            </v-list-item>
          </v-list>

        </v-menu>

        <v-btn
          v-if="feature('navigate') && item.dataset.files.length == 1"
          class="mr-2"
          small
          title="Go to File"
          @click="$router.push(`/files/${item.dataset.files[0].$oid}`)"
        >
            <v-icon style="transform: scaleX(-1);">
              mdi-file-send-outline
            </v-icon>
        </v-btn>


        <v-btn
          v-if="feature('navigate') && CONFIG.conf_pred=='false'"
          class="mr-2"
          small
          title="Go to Dataset"
          @click="$router.push(`/datasets/${item['dataset']['_id']['$oid']}`)"
        >
          <v-icon>mdi-table-arrow-left</v-icon>
        </v-btn>

      </v-btn-toggle>


<!-- Icons that open dialogs -->
      <v-btn-toggle dense>

        <ACLDialog
          v-if="canACL()"
          :items="[item]"
          object-type="method"
          button-title="mdi-account-key-outline"
          title="Access Control"
        />

        <DeleteDialog
          v-if="canDelete()"
          :items="[item]"
          object-type="method"
          @click="deleteItems([item])"
          button-title="mdi-trash-can-outline"
          title="Delete"
        />

      </v-btn-toggle>

    </v-toolbar>

    <div class="content">
      <v-text-field v-model="descriptors" class="ml-5" label="Model Descriptors" readonly />

      <div class="method-stats">
        <div v-for="m in Object.keys(metrics)" :key="`${m}`" class="method-stat">
          {{ metrics[m] }}
          <v-chip v-if="item.metrics" :color="getMetricColor(m, item.metrics[m])" small style="font-size: unset">
              {{ roundup(item.metrics[m], 2) }}
          </v-chip>
        </div>
      </div>

      <div class="method-graph">

        <Plotly 
          v-if="item.metrics.cross_val_predict && item.method_name.endsWith('r')" 
          :data="item.metrics.cross_val_predict" 
          :layout="layout_reg" 
          :scrollZoom='true'
          :displaylogo='false'
          displayModeBar=true
          responsive=true                  
          :modeBarButtonsToRemove="[ 'toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"
          :toImageButtonOptions="{format: 'svg', scale: 1}"
        ></Plotly>

        <Plotly 
          v-if="item.metrics.roc"
          :data="item.metrics.roc" 
          :layout="layout_roc"
          :scrollZoom='true'
          :displaylogo='false'
          displayModeBar=true
          responsive=true                  
          :modeBarButtonsToRemove="[ 'toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"
          :toImageButtonOptions="{format: 'svg', scale: 1}"
        ></Plotly>

      </div>

    </div>
        
  </v-card>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog';
import ViewBase from '@/components/ViewBase';
import ModelBase from '@/components/ModelBase';
import ACLDialog from '@/components/auth/ACLDialog';

import { getActivityColor } from '@/main';
import { Plotly } from 'vue-plotly';
import { mapGetters } from 'vuex';

export default {

  components: { ACLDialog, DeleteDialog, Plotly },

  mixins: [ViewBase, ModelBase],

  data: () => ({
    layout_roc: {
      title: "5-fold Cross Validation ROC",
      responsive: true,
      autosize: true,
      showlegend:false,
      modebar: {
        color: '#555555',
        activecolor: '#315FB5',
      },
    },
    layout_reg: {
      title: "Cross-Validation Predictions",
      responsive: true,
      autosize: true,
      showlegend:false,
      modebar: {
        color: '#555555',
        activecolor: '#315FB5',
      },
    },
  }),

  computed: {
    ...mapGetters(['CONFIG']),
    OBJECTS_TYPE() {
      return 'models';
    },
    descriptors() {
      return JSON.stringify(this.item.descriptors);
    },
    headers () {
      return this.item.metrics;
    }
  },

  methods: {
    getActivityColor(value) {
      return getActivityColor(value);
    }
  }
};
</script>