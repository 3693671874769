<template>
  <v-card>

    <v-toolbar>
      <v-btn class="mr-3" small title="All Read-across Results" to="/readacross">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      {{ item.name }}

      <v-spacer />

      <v-btn-toggle v-if="feature('view-tile')" v-model="view" class="mr-2" dense>
        <!-- Stats Table -->
        <v-btn small>
          <v-icon>mdi-format-list-checkbox</v-icon>
        </v-btn>
        <!-- knn Graph -->
        <v-btn small>
          <v-icon>mdi-chart-areaspline</v-icon>
        </v-btn>
      </v-btn-toggle>
      

      <!-- Download Options -->
      <v-btn-toggle class="mr-3">
        <v-btn
          v-if="feature('download-xlsx')"
          small
          title="Export to Excel"
          @click="downloadItem(`${item.name}.xlsx`, 'xlsx')"
        >
          <v-icon v-if="downloading && ext === 'xlsx'" class="mdi-spin">mdi-rotate-right</v-icon>
          <v-icon v-else>mdi-file-excel-outline</v-icon>
        </v-btn>
        <v-btn 
          v-if="feature('download-sdf')" 
          small 
          title="Download SDF" 
          @click="downloadItem(`${item.name}.sdf`, 'sdf')"
        >
          <v-icon v-if="downloading && ext === 'sdf'" class="mdi-spin">mdi-rotate-right</v-icon>
          <v-icon v-else>mdi-download-outline</v-icon>
        </v-btn>
        <v-btn 
          v-if="feature('download-image')" 
          small 
          title="Download Image" 
          @click="downloadItem(`${item.name}.png`, 'png')"
        >
          <v-icon v-if="downloading && ext === 'png'" class="mdi-spin">mdi-rotate-right</v-icon>
          <v-icon v-else>mdi-file-image-outline</v-icon>
        </v-btn>
      </v-btn-toggle>

      <!-- Dailogs -->
      <v-btn-toggle dense>
        <!-- <FileDialog
          v-if="feature('metadata-edit')"
          :allow-create="false"
          :item.sync="item"
          :title="metadataTitle"
          button-title="mdi-receipt-text-edit-outline"
          item-type="readacross"
              /> -->
        <ACLDialog
          v-if="canACL()"
          :items="[item]"
          :object-type="OBJECTS_TYPE"
          button-title="mdi-account-key-outline"
          title="Access Control"
        />
        <DeleteDialog
          v-if="canDelete()"
          :items="[item]"
          :object-type="OBJECTS_TYPE"
          button-title="mdi-trash-can-outline"
          title="Delete"
        />
      </v-btn-toggle>
    </v-toolbar>

    <v-data-table
      v-if="view === 0"
      :footer-props="{ showFirstLastPage: true, itemsPerPageOptions: [10, 20, 50, -1] }"
      :headers="headers"
      fixed-header
      :items="itemsForTable"
    >

      <template #item="{ item }">
        <tr>
          <td>
            <img :id="`i${item.id}`" :src="`${API_URL}render/${item.id}`" alt="" />
            <chem-popover :record="item" :target="`i${item.id}`"></chem-popover>
          </td>
          <td v-for="h in dataHeaders" :key="`${h.text}`" align="center">
            <template>
              <v-chip :color="getMetricColor(item[h.text])">
                {{ roundup(item[h.text], 3) }}
              </v-chip>
            </template>
          </td>
        </tr>
      </template>

    </v-data-table>
      
    <v-container v-if="view === 1">
      <div class="knn">
        <v-img
          :src="`${API_URL}readacross/${item._id.$oid}/image`" 
          alt=""
          class="knn-graph"
          height="100%"
          contain
          >
        </v-img>
        <div class="knn-title">k-Nearest Neighbors Graph</div>
      </div>  
    </v-container>
          
  </v-card>
</template>

<script>
import ReadAcrossBase from '@/components/ReadAcrossBase';
import ViewBase from '@/components/ViewBase';
import ChemPopover from '@/components/ChemPopover';
import DeleteDialog from '@/components/DeleteDialog';
import ACLDialog from '@/components/auth/ACLDialog';
// import FileDialog from '@/components/FileDialog';

export default {
  mixins: [ReadAcrossBase, ViewBase],
  components: { ACLDialog, ChemPopover, DeleteDialog}, // FileDialog

  data() {
    return {
      panel: 1,
      visibleReadAcross: false,
      selected_r: null
    }
  },

  computed: {
    // metadataTitle() {
    //   if (this.canWrite()) {
    //     return 'View/Edit Metadata'
    //   }
    //   else return 'View Metadata'
    // },
    OBJECTS_TYPE() {
      return 'readacross';
    },
    headers() {
      return this.headersNames;
    },
    dataHeaders() {
      return this.headersNames.filter((h) => h.value !== 'image');
    },
  },
};
</script>

