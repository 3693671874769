<template>
  <v-card>
      
    <v-toolbar>

      <v-btn class="mr-3" title="Models List" to="/models">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="ma-5">
        {{ modelName }}

        <v-badge class="ml-3" :content="statsText" color="blue lighten-2" overlap>
          <v-chip small>{{ items[0]['dataset']['records_number'] }}</v-chip>
        </v-badge>
        
      </span>

      <v-spacer />

      
      <!-- The set of icons linking the method to the file(s) and dataset that they came from -->
      <v-btn-toggle class="mr-5" dense>
        <v-btn
          small
          title="Go to Dataset"
          @click="$router.push(`/datasets/${items[0]['dataset']['_id']['$oid']}`)"
        >
          <v-icon>mdi-table-arrow-left</v-icon>
        </v-btn>
      </v-btn-toggle>
      
      <v-switch v-if="isAuthenticated && isSelectable" v-model="selectable" class="mt-4 mr-4" label="Select" />

      <!-- Exports -->
      <v-btn-toggle class="mr-2" dense>
        <v-btn 
          v-if="selectable && feature('download-xlsx')"
          :disabled="!selected.length && selectable"
          small 
          title="Export to Excel" 
          @click="downloadModels"
        >
          <v-icon v-if="downloading" class="mdi-spin">
            mdi-rotate-right
          </v-icon>
          <v-icon v-else>
            mdi-file-excel-outline
          </v-icon>
        </v-btn>
      </v-btn-toggle>



      <!-- Dialogs -->
      <v-btn-toggle dense>
        <ACLDialog
          v-if="canACL([item])"
          :disabled="!selected.length && selectable"
          :visible="selectable"
          :items="selected"
          object-type="method"
          button-title="mdi-account-key-outline"
          title="Access Control"
        />
        <DeleteDialog
          v-if="canDelete([item]) && feature('delete-multi')"
          v-model="visibleDelete"
          :disabled="!selected.length && selectable"
          :items="selected"
          :visible="selectable"
          button-title="mdi-trash-can-outline"
          object-type="method"
          @click="deleteItems(selected)"
          @deleted="items.splice(items.indexOf(selected[0]), 1)"
        />
      </v-btn-toggle>

    </v-toolbar>

    <div class="content">

      <v-data-table 
        v-model="selected"
        :headers="modelsHeaders"
        hide-default-footer
        :item-key="tableKey"
        :items="items"
        :show-select="selectable"
      >

        <template #item.method_name="{ item }">
          <b-link :to="`/models/${modelName}/${item.id}`">{{ item.method_name }}</b-link>
        </template>

        <template v-for="col in Object.keys(allMetrics)" v-slot:[`item.${col}`]="{ item }">
          <span v-if="item[col]" :key="col">
            <v-chip v-if="coloredMetric(col)" :color="getMetricColor(col, item[col])" small style="font-size: unset">
              {{ roundup(item[col], 2) }}
            </v-chip>
            <v-chip v-else small style="font-size: unset">
              {{ roundup(item[col], 2) }}
            </v-chip>
          </span>
        </template>


        <template #item.access="{ item }">
          {{ item.acl.access }}
        </template>
        <template #item.created_by="{ item }">
          <span v-if="item.created_by">
            {{ item.created_by }}
          </span>
          <span v-else>Unknown</span>
        </template>

        <template #item.creation_time="{ item }">
          <span v-if="item.creation_time">
            {{ item.creation_time }}
          </span>
          <span v-else>Unknown</span>
        </template>



        <template #item.actions="{ item }">
          <div class="justify-end mr-0">
            <v-icon v-if="canDelete(item)" small title="Delete" @click="deleteItems([item])">
              mdi-trash-can-outline
            </v-icon>
          </div>
        </template>
      </v-data-table>

      <div class="modelviewGraphs">

        <v-card v-for="m in items" :key="m.method_name">
          <v-card-title class="pa-1 pl-2" style="font-size: smaller">

            <!-- Regression Stats Headers -->
            <span v-if="m.method_name && m.method_name.endsWith('r')" style="line-height: 20px;">
              <span style="font-size:16px;">{{ m.method_name }}</span>
              <br />R2={{ roundup(m.r2, 2) }}, MAE={{ roundup(m.mae, 2) }}, RMSE={{
                roundup(m.rmse, 2)
              }}
              <span v-if="m.mpd">, MPD={{ roundup(m.mpd, 2) }}</span>
              <span v-if="m.mgd">, MGD={{ roundup(m.mgd, 2) }}</span>
            </span>

          <!-- Classification Stats Headers -->
            <span v-else style="line-height: 20px;">
              <span style="font-size:16px; text-transform: uppercase; line-height:20px;">{{ m.method_name }}</span>
              <br />AUC={{ roundup(m.auc, 2) }}, F1={{ roundup(m.f1score, 2) }}
            </span>
              
          
          </v-card-title>

          <!-- Regression Plots -->
          <v-card-text v-if="m.cross_val_predict && m.method_name.endsWith('r')" class="pa-1">
            <Plotly 
              :data="m.cross_val_predict" 
              :layout="layout_reg" 
              :scrollZoom='true'
              :displaylogo='false'
              displayModeBar=true
              responsive=true              
              :modeBarButtonsToRemove="[ 'toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"
              :toImageButtonOptions="{format: 'svg', scale: 1}"
            ></Plotly>
          </v-card-text>

          <!-- Classification Plots -->
          <v-card-text v-if="m.roc" class="pa-1">
            <Plotly 
              :data="m.roc" 
              :layout="layout_roc" 
              :scrollZoom='true'
              :displaylogo='false'
              displayModeBar=true
              responsive=true                  
              :modeBarButtonsToRemove="[ 'toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"
              :toImageButtonOptions="{format: 'svg', scale: 1}"
            ></Plotly>
          </v-card-text>

          <!-- Histogram -->
          <v-card-text v-if="m.y_pred && m.y_true" class="pa-1 pt-5">
            <Plotly 
              :data="hist_data(m)" 
              :layout="layout_hist"
              :scrollZoom='true'
              :displaylogo='false'
              displayModeBar=true
              responsive=true                  
              :modeBarButtonsToRemove="[ 'toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"
              :toImageButtonOptions="{format: 'svg', scale: 1}"
            ></Plotly>
          </v-card-text>

          <v-card-text v-if="m.fn && m.fp && m.tp && m.tn" class="pa-1 pt-5 pb-5 mx-3" style="justify-content: center; flex-direction: column; max-width: 280px;">
            <span style="font-family: 'Open Sans', Verdana, arial, sans-serif; color: rgba(0,0,0,.8); font-size: 16px; text-align: center;">Model Truth Table</span><br>
            <table border="1">
              <tr>
                <td class="correct">True Positive<br />
                    {{ m.tp }}
                </td>
                <td class="incorrect">False Postive<br />
                    {{ m.fp }}
                </td>
              </tr>
              <tr>
                <td class="incorrect">False Negative<br />
                    {{ m.fn }}
                </td>
                <td class="correct">True Negative<br />
                    {{ m.tn }}
                </td>
              </tr>
            </table>
          </v-card-text>
          
        </v-card>

      </div>

    </div>

  </v-card>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog';
import TableBase from '@/components/TableBase';
import ModelBase from '@/components/ModelBase';
import ACLDialog from '@/components/auth/ACLDialog';

import { download } from '@/main';
import { Plotly } from 'vue-plotly';
export default {
  components: { DeleteDialog, Plotly, ACLDialog }, 

  mixins: [TableBase, ModelBase],

  data: () => ({
    layout_roc: {
      title: {
        text: "5-fold Cross Validation ROC",
        yref: 'paper',
        xref: 'paper',
        yanchor: 'bottom',
        y: 1,
        pad: {
          b:5,
          l:5,
          r:5,
          t:5
        },
        xanchor: 'center',
      },
      responsive: true,
      autosize: true,
      width: 300,
      height: 300,   
      showlegend:false,
      margin: {
        l: 30,
        r: 10,
        b: 30,
        t: 60,
        pad: 0
      },
      modebar: {
        color: '#555555',
        activecolor: '#315FB5',
      },
      shapes: [
        {
          type: 'line',
          x0: 0,
          y0: 0,
          x1: 1,
          y1: 1,
          line: {
            color: '#999999',
            width: 2,
            dash: 'dot'
          }
        }
      ]
    },
    layout_hist: {
      // bargap: 0.05, 
      // bargroupgap: 0.2, 
      barmode: "overlay", 
      title: {
        text: "Model Evaluation Overlay",
        yref: 'paper',
        xref: 'paper',
        yanchor: 'bottom',
        y: 1,
        pad: {
          b:5,
          l:5,
          r:5,
          t:5
        },
        xanchor: 'center',
      },
      responsive: true,
      autosize: true,
      width: 300,
      height: 300,   
      showlegend:false,
      margin: {
        l: 30,
        r: 10,
        b: 30,
        t: 60,
        pad: 0
      },
      modebar: {
        color: '#555555',
        activecolor: '#315FB5',
      },
      xaxis: {title: "Value"}, 
      yaxis: {title: "Count"}
    },
    metricsType: 'int',
    metricsGroups: ['cls', 'reg'],
    visibleCreateModels: false,
    allHeaders: [
      // { text: 'Model', value: 'name' },
      { text: 'Method', value: 'method_name' },
      // { text: 'Training Time, s', value: 'execution_time', align: 'end', groupable: false },
      { text: 'Metrics', value: 'metrics', sortable: false, groupable: false },
      { text: 'Created By', value: 'created_by', initial: true, authenticated: false },
      // { text: 'Rights', value: 'rights', sortable: false, groupable: false, initial: false, admin: true },
      { text: 'Created', value: 'creation_time', initial: true },
      { text: 'Access', value: 'access', initial: true, authenticated: false },
      { text: 'Actions', value: 'actions', sortable: false, align: 'end', groupable: false, fixed: true },
    ],
    filename: null
  }),

  computed: {
    OBJECTS_TYPE() {
      return 'models';
    },
    isSelectable() {
      return this.feature('delete-multi') || this.feature('download-xlsx');
    },
    modelName() {
      return this.$route.params.name;
    },
    hasClsModel() {
      let rs = this.items.filter(m => m.method_name && !m.method_name.endsWith('r'));
      return !!(rs && rs.length);
    },
    hasRegModel() {
      let rs = this.items.filter(m => m.method_name && m.method_name.endsWith('r'));
      return !!(rs && rs.length);
    },
    modelMetricsHeaders() {
      let res = [];
      if (this.hasClsModel) {
        if (this.metricsType == null || this.metricsType === 'int') res = res.concat(this.clsHeaders);
      }
      if (this.hasRegModel) {
        if (this.metricsType == null || this.metricsType === 'int') res = res.concat(this.regHeaders);
      }
      return res;
    },
    modelsHeaders() {
      return [
        { text: 'Method', value: 'method_name' },
        ...this.modelMetricsHeaders,
        { text: 'Created at', value: 'creation_time' },
        { text: 'Created by', value: 'created_by'},
        { text: 'Actions', value: 'actions', sortable: false, align: 'end', groupable: false },
      ];
    },
    tableKey() {
      return 'method_name';
    },
    statsText() {
      if (!this.items[0].dataset.stats) return '';
      if (this.items[0].dataset.stats.actives) return `${this.items[0].dataset.stats.actives}/${this.items[0].dataset.stats.inactives}`;
      if (this.items[0].dataset.stats.high_value)
        return `${this.roundup(this.items[0].dataset.stats.low_value, 2)}-${this.roundup(this.items[0].dataset.stats.high_value, 2)}`;
      return '';
    },
    layout_reg() {

      let low_value = Math.floor(this.items[0].cross_val_predict)
      let high_value = Math.round(this.items[0].cross_val_predict)

      return {
        title: {
          text: "Cross-Validation Predictions",
          yref: 'paper',
          xref: 'paper',
          yanchor: 'bottom',
          y: 1,
          pad: {
            b:5,
            l:5,
            r:5,
            t:5
          },
          xanchor: 'center',
        },
        xaxis: {range: [low_value, high_value]},
        yaxis:  {range: [low_value, high_value]},
        responsive: true,
        // autosize: true,
        width: 300,
        height: 300,     
        showlegend:false,
        margin: {
          l: 30,
          r: 10,
          b: 30,
          t: 60,
          pad: 0
        },
        modebar: {
          color: '#555555',
          activecolor: '#315FB5',
        },
        shapes: [
          {
            type: 'line',
            x0: low_value,
            y0: low_value,
            x1: high_value,
            y1: high_value,
            line: {
              color: '#999999',
              width: 2,
              dash: 'dot'
            }
          }
        ]
      }
    } 
  },

  watch: {
    modelName() {
      this.getData();
      this.selected = [this.modelName];
    }
  },

  methods: {
  
    hist_data(method) {

      let pred_vals = [];
      let true_vals = [];
      let i = 0;
      for (let val of method.y_true) {
        if (val === 0) {
          pred_vals.push(method.y_prob[i])
        }
        i++;
      }
      let j = 0;
      for (let val of method.y_true) {
        if (val === 1) {
          true_vals.push(method.y_prob[j])
        }
        j++;
      }

      var trace1 = {
        x: pred_vals,
        name: 'Predicted Values',
        autobinx: false, 
        histnorm: "count", 
        marker: {
          color: "rgb(219, 30, 58, .7)", 
          line: {
            color:  "rgb(219, 30, 58, 1)", 
            width: 1
          }
        },  
        opacity: 0.5, 
        type: "histogram", 
        xbins: {
          end: 1, 
          size: 0.025, 
          start: 0
          }
        };

      var trace2 = {
        x: true_vals,
        name: "True Values", 
        histnorm: "count",
        autobinx: false, 
        marker: {
                color: "rgb(94, 146, 219, .7)",
                line: {
                  color:  "rgb(94, 146, 219, 1)", 
                  width: 1
          } 
            }, 
        opacity: 0.5, 
        type: "histogram", 
        xbins: { 
          end: 1, 
          size: 0.025, 
          start: 0
          }
        };

      return [trace1, trace2]
    },
    downloadModels() {

      this.downloading = true;
      this.$axios
        .post(
          `models/download`,
          {
            names:
              [this.modelName],              
            ids:
              this.modelName && this.selected.length
                ? this.selected.map(m => (typeof m === 'string' ? m : m.id))
                : [],
          },
          { responseType: 'blob' }
        )
        .then(response => {
          this.downloading = false;
          this.filename = response.headers['content-disposition'];
          this.filename = this.filename.substring(this.filename.indexOf("filename=") + "filename=".length, this.filename.length);
          download(this.filename ? this.filename : `${this.OBJECTS_TYPE}.xlsx`, response.data)

        })
        .catch(() => (this.downloading = false));
    },
  },
};
</script>
