<script>
import { API_URL, download, roundup } from '@/main';
import AuthBase from '@/components/auth/AuthBase';
import { mapGetters } from 'vuex';

export default {
  mixins: [AuthBase],

  data() {
    return {
      // items: [
      //   {
      //     id: null,
      //     name: "null",
      //     mol_metadata: {},
      //     datasets_name: [],
      //     images: {},
      //     molecule: {},
      //     fields_mapping: [],
      //     nn_molecues_values: [],
      //     read_across_results: [],
      //   },
      // ],
      read_name: 'GenRA Results',
      kav: null,
      search: null,
      downloading: false,
      view: 0,
    };
  },

  computed: {
    ...mapGetters(['feature']),
    API_URL() {
      return API_URL;
    },
    AUTO_REFRESH() {
      return null;
    },
    OBJECTS_TYPE() {
      return null;
    },
    // headersFields: function () {
    //   return this.item
    //     ? [
    //         { text: 'Structure', value: 'image', sortable: false },
    //         { name: 'Name', value: 'name' },
    //       ]
    //     : [];
    // },
    // headersNames() {
    //   return this.item
    //     ? [{ text: 'Structure', value: 'image', sortable: false }].concat(
    //         this.item.fields_mapping.filter((f) => f.type).map((f) => ({ text: f.name, value: f.name }))
    //       )
    //     : [];
    // },
    // headersTypes: function () {
    //   return this.item
    //     ? [
    //         { text: 'Structure', value: 'image', sortable: false },
    //         { text: 'Read Across', value: 'read_across', sortable: false, align: 'left' },
    //       ].concat(this.item.fields_mapping.filter((f) => f.type).map((f) => ({ text: f.name, value: f.name })))
    //     : [];
    // },
    items() {
      return this.item;
    },
    itemsForTable() {
      return this.item
        ? this.item.nn_molecules.map((r) => {
            if (r.mol) r.fields.id = r.mol.$oid;
            return r.fields;
          })
        : [];
    },
  },

  methods: {
    roundup(value, digits) {
      return roundup(value, digits);
    },
    getMetricColor(value) {
      if (value == null || isNaN(value)) return '';

      if (value >= 0.9) return 'red';
      if (value >= 0.8) return 'amber';
      if (value >= 0.7) return 'amber';
      if (value >= 0.6) return 'lime';
      if (value >= 0.5) return 'yellow';
      if (value >= 0.3) return 'light-green';
      return 'green';
    },
    downloadItem(filename, format) {
      this.downloading = true;
      const fmt = format ? `?format=${format}` : '';
      this.$axios
        .get(`${this.OBJECTS_TYPE}/${this.item._id.$oid}/download${fmt}`, { responseType: 'blob' })
        .then((response) => {
          this.downloading = false;
          download(filename && typeof filename === 'string' ? filename : this.item.name, response.data);
        })
        .catch(() => (this.downloading = false));
    },
    getData() {
      this.$axios.get(`${this.OBJECTS_TYPE}/${this.$route.params.id}`).then((response) => {
        this.item = response.data;
        this.kav = response.data;
      });
    },
  },

  watch: {
    view(val) {
      if (val === 0 || val === 1) this.$router.push(`${this.$route.path}?view=${val}`);
    },
  },

  mounted() {
    this.getData();
    if (this.AUTO_REFRESH) this.timer = setInterval(this.getData, this.AUTO_REFRESH);
  },

  beforeDestroy() {
    if (this.timer != null) clearInterval(this.timer);
  },
};
</script>
