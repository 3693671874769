<template>
  <v-card>
      
    <v-toolbar>

      <v-text-field
        v-if="feature('table-search')"
        v-model="search"
        append-icon="mdi-magnify"
        class="mr-3"
        hide-details
        label="Search in any field"
        single-line
      />

      <template>
        <v-col v-if="ensembleModeItems.length" cols="2">
          <v-select 
            v-model="ensembleMode" 
            :items="ensembleModeItems" 
            class="mt-6" 
            density="compact" 
            label="Ensemble score" />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="metricsGroups"
            :items="availableMetricsGroups"
            class="mt-6"
            density="compact" 
            label="Statistics Headers"
            multiple
          />
        </v-col>
      </template>

      <v-spacer />

      <v-switch v-if="isAuthenticated && isSelectable" v-model="selectable" class="mt-4 mr-4" label="Select" />

      <v-btn-toggle class="mr-2" dense>
        <v-btn 
          v-if="selectable && feature('download-xlsx')"
          :disabled="!selected.length && selectable"
          small 
          title="Export to Excel" 
          @click="downloadModels"
        >
          <v-icon v-if="downloading" class="mdi-spin">
            mdi-rotate-right
          </v-icon>
          <v-icon v-else>
            mdi-file-excel-outline
          </v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle dense v-if="selectable">
        <ACLDialog
          v-if="canACL([item]) && selectable"
          :disabled="!selected.length && selectable"
          :items="selected"
          object-type="model"
          button-title="mdi-account-key-outline"
          title="Access Control"
        />
        <HeadersDialog
          v-if="!ensembleMode && feature('table-headers')"
          :available-headers="availableHeaders"
          :headers.sync="headers"
        />
        <DeleteDialog
          v-if="canDelete([item]) && feature('delete-multi')"
          v-model="visibleDelete"
          :disabled="!selected.length && selectable"
          :items="selected"
          button-title="mdi-trash-can-outline"
          object-type="model"
          @click="deleteItems(selected)"
          @deleted="items.splice(items.indexOf(selected[0]), 1)"
        />
      </v-btn-toggle>
    </v-toolbar>

    <v-data-table
      v-model="selected"
      :footer-props="{ showFirstLastPage: true, itemsPerPageOptions: [10, 20, 50, -1] }"
      :headers="modelsHeaders"
      :hide-default-footer="items.length < 1"
      :item-key="tableKey"
      :items="items"
      :items-per-page="50"
      :search="search"
      :show-group-by="!ensembleMode"
      :show-select="selectable"
      fixed-header
      sort-by="dateCreated"
      sort-desc
    >

      <template #item.name="{ item }">
        <b-link :title="item.name" :to="`/models/${item.name}`" class="wrap-300">
          {{ item.name }}
        </b-link>
      </template>


      <template v-for="col in Object.keys(allMetrics)" v-slot:[`item.${col}`]="{ item }">
          <span v-if="item[col]" :key="col">
            <v-chip v-if="coloredMetric(col)" :color="getMetricColor(col, item[col])" small style="font-size: unset">
              {{ roundup(item[col], 2) }}
            </v-chip>
            <v-chip v-else small style="font-size: unset">
              {{ roundup(item[col], 2) }}
            </v-chip>
          </span>
        </template>



      <template #item.access="{ item }">
        {{ item.acl.access }}
      </template>
      <template #item.owner="{ item }">
        {{ getUser(item.acl.owner) }}
      </template>
      
      <template #item.created_by="{ item }">
          <span v-if="item.created_by">
            {{ item.created_by }}
          </span>
          <span v-else>Unknown</span>
        </template>

        <template #item.creation_time="{ item }">
          <span v-if="item.creation_time">
            {{ item.creation_time }}
          </span>
          <span v-else>Unknown</span>
        </template>

    </v-data-table>


  </v-card>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog';
import TableBase from '@/components/TableBase';
import ModelBase from '@/components/ModelBase';
import HeadersDialog from '@/components/HeadersDialog';
import ACLDialog from '@/components/auth/ACLDialog';

import { download } from '@/main';

export default {
  components: { HeadersDialog, DeleteDialog, ACLDialog }, 

  mixins: [TableBase, ModelBase],

  data: () => ({
    mode: 'max',
    metricsType: 'int',
    metricsGroups: ['cls', 'reg'],
    visibleCreateModels: false,
    allHeaders: [
      { text: 'Model', value: 'name' },
      { text: 'Method', value: 'method_name' },
      { text: 'Metrics', value: 'metrics', sortable: false, groupable: false },
      { text: 'Training Time, s', value: 'execution_time', align: 'end', groupable: false },
      { text: 'Access', value: 'access', initial: false, authenticated: true },
      { text: 'Owner', value: 'owner', initial: false, authenticated: true },
      { text: 'Rights', value: 'rights', sortable: false, groupable: false, initial: false, admin: true },
      { text: 'Created By', value: 'created_by', initial: true },
      { text: 'Creation Time', value: 'creation_time', initial: true }
      // { text: 'Actions', value: 'actions', sortable: false, align: 'end', groupable: false, fixed: true },
    ]
  }),

  computed: {
    OBJECTS_TYPE() {
      return 'models';
    },
    ROC_DATA(m) {
      return m.roc;
    },
    QUERY() {
      return `?aggr=${this.ensembleMode}`;
    },
    isSelectable() {
      return this.feature('delete-multi') || this.feature('download-xlsx');
    },
    hasClsModel() {
      let rs = this.items.filter(m => m.method_name && !m.method_name.endsWith('r'));
      return !!(rs && rs.length);
    },
    hasRegModel() {
      let rs = this.items.filter(m => m.method_name && m.method_name.endsWith('r'));
      return !!(rs && rs.length);
    },
    ensembleMode: {
      get() {
        return this.ensembleModeItems.length === 0 ? null : this.mode;
      },
      set(mode) {
        this.mode = mode;
      },
    },
    ensembleModeItems() {
      return Array.isArray(this.feature('models-ensemble-score'))
        ? [...this.feature('models-ensemble-score')]
        : [];
    },
    availableMetricsGroups() {
      return this.feature('models-metrics-groups');
    },
    modelMetricsHeaders() {
      let res = [];
      if (this.hasClsModel) {
        if (this.metricsType == null || this.metricsType === 'int') res = res.concat(this.clsHeaders);
      }
      if (this.hasRegModel) {
        if (this.metricsType == null || this.metricsType === 'int') res = res.concat(this.regHeaders);
      }
      return res;
    },
    modelsMetricsHeaders() {
      let res = [];
      if (this.ensembleMode) {
        if (this.metricsGroups.includes('reg')) res = res.concat(this.regHeaders);
        if (this.metricsGroups.includes('cls')) res = res.concat(this.clsHeaders);
      }
      return res;
    },
    modelsHeaders() {
      return [
        { text: 'Model', value: 'name' },
        { text: 'N', value: 'n' },
        ...this.modelsMetricsHeaders,
        { text: 'Created By', value: 'created_by' },
        { text: 'Creation Time', value: 'creation_time' }
        // { text: 'Actions', value: 'actions', align: 'end', sortable: false },
      ];
    },
    tableKey() {
      return 'name';
    }
  },

  watch: {
    ensembleMode() {
      this.getData();
    }
  },

  methods: {
    // customSearch(value, search, item) {
    //   search = search.toLowerCase();
    //   return Object.values(this.flatItem(item)).some(value => value?.toString()?.toLowerCase()?.includes(search));
    // },
    // flatItem(item) {
    //   return {
    //     // name: item.name,
    //     // project: item.metadata.project,
    //     // description: item.metadata.description,
    //     // measurementType: item.metadata.measurementType,
    //     // target: item.metadata.target,
    //     // organism: item.metadata.organism,
    //     // records_number: item.records_number,
    //     access: item.acl.access
    //   };
    // },
    downloadModels() {

      this.downloading = true;
      this.$axios
        .post(
          `${this.OBJECTS_TYPE}/download`,
          {
            names:
              this.selected.map(m => (typeof m === 'string' ? m : m.name)),              
            ids:
              [],
          },
          { responseType: 'blob' }
        )
        .then(response => {
          this.downloading = false;
          this.filename = response.headers['content-disposition'];
          this.filename = this.filename.substring(this.filename.indexOf("filename=") + "filename=".length, this.filename.length);
          download(this.filename ? this.filename : `${this.OBJECTS_TYPE}.xlsx`, response.data)

        })
        .catch(() => (this.downloading = false));
    },
  },
};
</script>
