import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

import Home from '@/views/Home.vue';

import FilesTable from '@/components/FilesTable';
import FileView from '@/components/FileView';

import DatasetsTable from '@/components/DatasetsTable';
import DatasetView from '@/components/DatasetView';
import ProtocolView from '@/components/ProtocolView';

import ModelsTable from '@/components/ModelsTable';
import ModelView from '@/components/ModelView';
import MethodView from '@/components/MethodView';

import StructurePredict from '@/components/StructurePredict';

import PredictionsTable from '@/components/PredictionsTable';
import PredictionsView from '@/components/PredictionsView';
import ValidationsView from '@/components/ValidationsView';

import JobsTable from '@/components/JobsTable';
import JobView from '@/components/JobView';

import UsersTable from '@/components/auth/UsersTable';

import ReadAcrossTable from '@/components/ReadAcrossTable'
import ReadAcrossView from '@/components/ReadAcrossView'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/files',
    component: FilesTable,
  },
  {
    path: '/files/:id',
    name: 'file',
    component: FileView,
  },
  {
    path: '/datasets',
    component: DatasetsTable,
  },
  {
    path: '/datasets/:id',
    name: 'dataset',
    component: DatasetView,
  },
  {
    path: '/models',
    component: ModelsTable,
  },
  {
    path: '/models/:name',
    component: ModelView,
  },
  {
    path: '/models/:name/:id',
    component: MethodView,
  },
  {
    path: '/predict',
    component: StructurePredict,
  },
  {
    path: '/resultsets',
    component: PredictionsTable,
  },
  {
    path: '/resultsets/:id',
    name: 'resultset',
    component: PredictionsView,
  },
  {
    path: '/resultsets/:id/validations',
    name: 'validations',
    component: ValidationsView,
  },
  {
    path: '/jobs',
    component: JobsTable,
    meta: { admin: true },
  },
  {
    path: '/jobs/:id',
    name: 'job',
    component: JobView,
    meta: { admin: true },
  },
  {
    path: '/protocols/:id',
    name: 'protocol',
    component: ProtocolView,
  },
  {
    path: '/users',
    name: 'users',
    component: UsersTable,
    meta: {admin: true },
  },
  {
    path: '/readacross',
    component: ReadAcrossTable
  },
  {
    path: '/readacross/:id',
    name: 'readacross',
    component: ReadAcrossView,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.admin)) {
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    else {
      next('/');
    }
  } else {
    next();
  }
});

export default router;
