<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    dialog: false,
    message: null,
  }),

  computed: {
    ...mapGetters(['feature']),
    formTitle() {
      return 'Create';
    },
  },

  watch: {
    value(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit('input', val);
    },
  },
};
</script>
