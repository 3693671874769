<template>
  <v-card>

    <v-toolbar>
      <v-btn class="mr-3" small title="All Files" to="/files">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      {{ item.name }}

      <span>
        <v-chip small class="ml-3">{{ item.records_number }}</v-chip>
      </span>

      <v-spacer />




      <v-btn-toggle v-if="feature('view-tile')" v-model="view" class="mr-2" dense>
        <v-btn small>
          <v-icon>mdi-format-list-checkbox</v-icon>
        </v-btn>
        <v-btn small>
          <v-icon>mdi-view-grid-outline</v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle class="mr-2" dense>

        <v-btn 
          v-if="feature('download')" 
          small 
          title="Download File" 
          @click="downloadFile(`${item.name}`)"
        >
          <v-icon v-if="downloading" class="mdi-spin">
            mdi-rotate-right
          </v-icon>
          <v-icon v-else>
            mdi-download-outline
          </v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle dense>
        <template v-if="feature('navigate') && item.protocol.length > 0">
          <v-dialog
            v-model="protocol"
            width="400">

            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                elevation="0"
                
                title="Protocol View"
                v-bind="attrs"
                v-on="on"
              ><v-icon>mdi-clipboard-alert-outline</v-icon></v-btn>
            </template>
            
            <v-card>
              <v-card-title>Rejected CAS Numbers</v-card-title>
              <v-card-subtitle class="font-weight-bold">
                <v-row>
                  <v-col>CAS Num</v-col>
                  <v-col>Error Message</v-col>
                </v-row>
              </v-card-subtitle>
              <v-card-text v-for="item in item.protocol" :key="item[1]">
                <v-row>
                  <v-col>{{ item[1] }}</v-col>

                  <v-col>{{ item[0] }}</v-col>
                </v-row>
                </v-card-text>
              <v-card-actions>
                <v-btn color="primary"  @click="protocol = false">Close</v-btn>
              </v-card-actions>
            </v-card>

          </v-dialog>
            
        </template>

        <FileDialog
          v-if="canWrite() && feature('metadata-edit')"
          :allow-create="false"
          :item.sync="item"
          title="Edit/View Metadata"
          button-title="mdi-receipt-text-edit-outline"
          item-type="files"
        />
              
        <DatasetDialog
          v-if="canCreate && CONFIG.title != 'MegaTox'"
          :files="[item]"
          button-title="mdi-table-plus"
          title="Create Dataset"
          type="datasets"
        />

        <DatasetDialog
          v-if="canCreate"
          :files="[item]"
          button-title="mdi-shape-polygon-plus"
          title="Create Predictions"
          type="resultsets"
        />
        <ACLDialog
          v-if="canACL()"
          :items="[item]"
          :object-type="OBJECTS_TYPE"
          button-title="mdi-account-key-outline"
          title="Access Control"
        />
        <DeleteDialog
          v-if="canDelete()"
          :items="[item]"
          :object-type="OBJECTS_TYPE"
          button-title="mdi-trash-can-outline"
          title="Delete"
        />
      </v-btn-toggle>
    </v-toolbar>

    <v-data-table
        v-if="view !== 1"
        :footer-props="{ showFirstLastPage: true, itemsPerPageOptions: [10, 20, 50, -1] }"
        :headers="headers"
        :items="items"
        :search="search"
        fixed-header
      >
        <template v-slot:item.image="{ item }">
          <img :id="`${item.id}`" :src="`${API_URL}render/${item.id}`" alt="" />
          <chem-popover :record="item" :target="`${item.id}`"></chem-popover>
        </template>
    </v-data-table>
    

    <v-container v-else class="recordstile">
      <records-tile :recordset="item"></records-tile>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import DatasetDialog from '@/components/DatasetDialog';
import DeleteDialog from '@/components/DeleteDialog';
import RecordsTile from '@/components/RecordsTile';
import ChemPopover from '@/components/ChemPopover';
import FileDialog from '@/components/FileDialog';
import ViewBase from '@/components/ViewBase';
import ACLDialog from '@/components/auth/ACLDialog';

export default {
  components: { ACLDialog, FileDialog, ChemPopover, DatasetDialog, DeleteDialog, RecordsTile },

  mixins: [ViewBase],

  data: () => ({
    protocol: false
  }),

  computed: {
    ...mapGetters(['CONFIG']),
    OBJECTS_TYPE() {
      return 'files';
    },
    headers() {
      return this.headersFields;
    },
  },
};
</script>
