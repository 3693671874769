<template>
  <v-card>

    <v-toolbar>

      <v-btn class="mr-5" title="All Resultsets" to="/resultsets">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      {{ item.name }}
          
      <span>
        <v-chip small class="ml-3">{{ item.records_number }}</v-chip>
      </span>

      <v-spacer></v-spacer>

      <v-select 
            v-if="ensembleModeItems.length > 1"
            v-model="ensembleMode" 
            :items="ensembleModeItems" 
            class="mt-6 ml-10 mr-5" 
            density="compact" 
            label="Ensemble score" 
            style="min-width: 150px;"/>


      <!-- Resultsets Views -->
      <v-btn-toggle v-if="feature('view-tile')" v-model="view" class="mr-2" dense>

        <!-- Stats Table -->
        <v-btn small>
          <v-icon>mdi-format-list-checkbox</v-icon>
        </v-btn>
        <!-- Structure View -->
        <v-btn small>
          <v-icon>mdi-view-grid-outline</v-icon>
        </v-btn>
        <!-- Predictions Graphs -->
        <v-btn small>
          <v-icon>mdi-chart-areaspline</v-icon>
        </v-btn>
        <!-- Validation Stats -->
        <v-btn
          v-if="item.valid_stats && item.valid_stats.length && feature('resultset-validations-view')"
          small
          title="Validations View"
          @click="$router.push(`${$route.path}/validations`)"
        >
          <v-icon>mdi-alpha-v-circle-outline</v-icon>
        </v-btn>
        
        <!--  Would protocol be some sort of metadata/model/prediction data display? -->
        <v-btn
          v-if="item.protocol && item.protocol.$oid"
          small
          title="Protocol"
          @click="$router.push(`/protocols/${item.protocol.$oid}`)"
        >
          <v-icon>mdi-clipboard-alert-outline</v-icon>
        </v-btn>
      </v-btn-toggle>

      <!-- Predictions Reference Files/Data -->
      <v-btn-toggle class="mr-2" dense>

        <v-menu v-if="feature('navigate') && item.files.length > 1" offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn v-bind="attrs" v-on="on" small title="Go to File">
              <v-icon style="transform: scaleX(-1);">
                mdi-file-send-outline
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="file in item.files"
              :key="file.$oid"
              link
              @click="$router.push(`/files/${file.$oid}`)"
            >
              <v-list-item-title v-text="`file-${file.$oid}`" />
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          v-if="feature('navigate') && item.files.length == 1"
          class="mr-2"
          small
          title="Go to File"
          @click="$router.push(`/files/${item.files[0].$oid}`)"
        >
            <v-icon style="transform: scaleX(-1);">
              mdi-file-send-outline
            </v-icon>
        </v-btn>
      </v-btn-toggle>

      <!-- Download Options -->
      <v-btn-toggle>
        <v-btn
          v-if="feature('download-xlsx')"
          small
          title="Export to Excel"
          @click="downloadItem(`${item.name}.xlsx`, `${threshold}-${item.conf_pred ? 'allStats': mode}-xlsx`)"
        > 
          <v-icon v-if="downloading && ext === 'xlsx'" class="mdi-spin">
            mdi-rotate-right
          </v-icon>
          <v-icon v-else>
            mdi-file-excel-outline
          </v-icon>
        </v-btn>
        <v-btn
          v-if="feature('download-sdf')"
          small
          title="Download SDF"
          @click="downloadItem(`${item.name}.sdf`, `${threshold}-${item.conf_pred ? 'allStats': mode}-sdf`)"
        >
          <v-icon v-if="downloading && ext === 'sdf'" class="mdi-spin">
            mdi-rotate-right
          </v-icon>
          <v-icon v-else>
            mdi-download-outline
          </v-icon>
        </v-btn>
      </v-btn-toggle>

      <!-- Dialogs -->
      <v-btn-toggle dense>
        <FileDialog
          v-if="canWrite() && feature('metadata-edit')"
          :allow-create="false"
          :item.sync="item"
          title="View/Edit Metadata"
          item-type="resultsets"
          button-title="mdi-receipt-text-edit-outline"

        />
        <ACLDialog
          v-if="canACL()"
          :items="[item]"
          :object-type="OBJECTS_TYPE"
          button-title="mdi-account-key-outline"
          title="Access Control"
        />
        <DeleteDialog
          v-if="canDelete()"
          :items="[item]"
          :object-type="OBJECTS_TYPE"
          button-title="mdi-trash-can-outline"
          title="Delete"
          @deleted="$router.push(`/${OBJECTS_TYPE}`)"
        />
      </v-btn-toggle>
    </v-toolbar>

    <v-toolbar v-if="item.conf_pred === true">

        <v-slider
          v-model="threshold"
          class="align-center"
          :max="threshold_max"
          :min="threshold_min"
          :step="threshold_step"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="threshold"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              :max="threshold_max"
              :min="threshold_min"
              :step="threshold_step"
              style="width: 60px;"
            ></v-text-field>
          </template>
        </v-slider>
    </v-toolbar>

    <v-data-table
      v-if="view === 0"
      :footer-props="{ showFirstLastPage: true, itemsPerPageOptions: [10, 20, 50, -1] }"
      :headers="headers"
      :items="items"
      :search="search"
      style="display:flex;"
      fixed-header
    >

      <template v-slot:header>
        <thead>
          <tr>
            <th style="text-align: center; white-space: nowrap;" :colspan="numMolInfoColumns" :width="headerWidth[0]">Molecule Information</th>
            <th 
              v-for="model, index in predictedStatColumns" 
              :key="index" 
              :colspan="Object.values(model)[0]"
              :width="headerWidth[index + 1]"
              :class="{column_diff: index%2==0}"
              style="text-align: center; white-space: nowrap"
            > 
              {{ Object.keys(model)[0] }}
            </th>
          </tr>
        </thead>
      </template>

      <template #item="{ item }">
        <tr>
          <td style="text-align: center;">
            <img :id="`i${item.id}`" :src="`${API_URL}render/${item.id}`" alt="molecular structure" />
            <chem-popover :record="item" :target="`i${item.id}`"></chem-popover>
          </td>
          <td 
            v-for="data in dataHeaders" 
            :key="data.value" 
            :class="{column_diff: predStatHeaders.indexOf(data.model)%2==0}"
            align="center"
          >
            <span v-if="data.type === 'mol_info'">
              {{ item[data.value] }}
            </span>
            <span 
              v-if="data.type === 'predicted_stat' && data.text === 'duplicate'"
              small>
              {{ item[data.value] === 1 ? "True" : "False" }}
            </span>
            <v-chip 
              v-else-if="data.type === 'predicted_stat' && (Array.isArray(item[data.value]) || data.value === 'mode')"
              small
              :color="data.value === 'mode' ? getThresholdMode(item)[1] : conformalPredictorsAssemble(item[data.value])[1]"
            >
                {{ data.value === 'mode' ? getThresholdMode(item)[0] : conformalPredictorsAssemble(item[data.value])[0] }}
            </v-chip>
            <v-chip 
              v-else-if="data.type === 'predicted_stat' && !Array.isArray(item[data.value])"
              small
              :color="getMetricColor(item[data.value])"
            >
              {{ item[data.value] == 1 || item[data.value] == 0 ? Number.parseFloat(item[data.value].toFixed(0)) : Number.parseFloat(item[data.value]).toFixed(2)}}
            </v-chip>             
          </td>
        </tr>
      </template>

    </v-data-table>

    <v-container v-if="view === 1" class="recordstile" >
      <records-tile :recordset="item"></records-tile>
    </v-container>

    <v-container v-if="view === 2" class="scroll resultset_graph_scroll" style="display: flex;">
      <plotly-graphs :item="item"></plotly-graphs>
    </v-container>
  </v-card>
</template>

<script>
import RecordsTile from '@/components/RecordsTile'
import PlotlyGraphs from '@/components/PlotlyGraphs'
import ChemPopover from '@/components/ChemPopover'
import DeleteDialog from '@/components/DeleteDialog'
import FileDialog from '@/components/FileDialog'
import ViewBase from '@/components/ViewBase'
import ACLDialog from '@/components/auth/ACLDialog'
import { mapGetters } from 'vuex'

export default {
  components: { FileDialog, ACLDialog, ChemPopover, DeleteDialog, RecordsTile, PlotlyGraphs }, 

  mixins: [ViewBase],

  data: () => ({
    mode: 'avg',
    threshold: 0.8,
    threshold_max: 0.95,
    threshold_min: 0.05,
    threshold_step: 0.05,
  }),

  computed: {
    ...mapGetters(['CONFIG']),
    OBJECTS_TYPE() {
      return 'resultsets';
    },
    average() {
      if (this.ensembleMode != 'allStats') {
        return true;
      }
      else return false;
    },
    headers() {
      let headerList = []

        .concat(this.item.fields_mapping          
          .filter(f => f.name.includes('SMILES'))
          .map(f => ({
            text: f.name,
            value: f.name,
            class: 'results-header',
            align: 'center',
            type: 'mol_info'
          })))
        .concat(this.item.fields_mapping
          .filter(f => f.type.includes('chem-name'))
          .map(f => ({
            text: f.name,
            value: f.name,
            class: 'results-header',
            align: 'center',
            type: 'mol_info'
          })))
        .concat(this.item.fields_mapping
          .filter(f => f.type.includes('chem-id'))
          .map(f => ({
            text: f.name,
            value: f.name,
            class: 'results-header',
            align: 'center',
            type: 'mol_info'
          })))
        .concat(this.item.fields_mapping
          .filter(f => f.type.includes('non-activity') && !f.name.includes('SMILES'))
          .map(f => ({
            text: f.name,
            value: f.name,
            class: 'results-header',
            align: 'center',
            type: 'mol_info'
          })))
        .concat(this.item.fields_mapping
          .filter(f => f.type.includes('ad-score'))
          .map(f => ({
            text: f.name.split('/')[1],
            model: f.name.split('/')[0],
            value: f.name,
            class: 'results-header',
            align: 'center',
            type: 'predicted_stat'
          })))
        .concat(this.item.fields_mapping
          .filter(f => (!this.average && f.type.includes('predicted-value') && !f.name.includes('/avg') && !f.name.includes('/min') && !f.name.includes('/max')) || (this.average && f.name.includes(`/${this.mode}`)))
          .map(f => ({
            text: f.name.split('/')[1],
            model: f.name.split('/')[0],
            value: f.name,
            class: 'results-header',
            align: 'center',
            type: 'predicted_stat'
          })))

      if (this.item.conf_pred) {
        const modelNameField = this.item.fields_mapping.find(map => map.type === 'ad-score');
        if (modelNameField) {
          const modelName = modelNameField.name.split('/')[0];
          let index = undefined;

          const dupIndex = headerList.findIndex(x => x.text === 'duplicate');
          const adIndex = headerList.findIndex(x => x.text === 'ad-score');


          if (dupIndex >= 0) {
            index = dupIndex
          } else if (adIndex >= 0) {
            index = adIndex
          }

          const newItem = {
              text: 'mode',
              model: modelName,
              value: 'mode',
              class: 'results-header',
              align: 'center',
              type: 'predicted_stat'
            }
          const dup = [...headerList];
          if (index !== undefined) {
            
            headerList = [...dup.slice(0,index+1), newItem, ...dup.slice(index+1)];
          } else {
            headerList.push(newItem);
          }
         
        }
      }

      headerList.sort(function (a, b) {  
        try {
          if (a.model.toLowerCase() < b.model.toLowerCase()) return -1;
          if (a.model.toLowerCase() > b.model.toLowerCase()) return 1;
          return 0;
        }
        catch (error) {
          console.log(error);
          return 0;
        }
      })

      return [{ text: 'Structure', value: 'image', align: 'center', sortable: false, filterable: false, type: 'mol_info' }].concat(headerList)

    },
    numMolInfoColumns() {
      const count = (list, searchTerm) => list.filter((x) => x.type === searchTerm).length
      return count(this.headers, 'mol_info')
    },
    predictedStatColumns() {
      
      const models = Object.values(
        this.headers.reduce((a, b) => {
          if (!a[b.model]) {
            a[b.model] = { [b.model]: 1 }
          }
          else {
            a[b.model][b.model]++
          }

          return a
        }, {})
      )
      return models.filter(u => Object.keys(u)[0] !== 'undefined');
    },
    predStatHeaders() {
      let predHeaders = []
      for (let predCol of this.predictedStatColumns) {
        predHeaders = predHeaders.concat(Object.keys(predCol))
      }
      return predHeaders;
    },
    headerWidth() {

      let predNums = [];
      for (let predCol of this.predictedStatColumns) {
        predNums = predNums.concat(Object.values(predCol))
      }
      let totalColumns = predNums.reduce((a, b) => a + b, 0) + this.numMolInfoColumns;
      let widths = [Math.floor(this.numMolInfoColumns / totalColumns * 100) + '%'];

      for (let n of predNums) {
        widths = widths.concat([Math.floor(n / totalColumns * 100) + '%']);
      }
      
      return widths;
    },
    dataHeaders() {
      return this.headers
        .filter(h => h.value !== 'image');
    },
    ensembleModeItems() {
      let all_mode_items = Array.isArray(this.feature('result-view-mode'))
      ? [...this.feature('result-view-mode')]
      : [];
      // if (this.item.fields_mapping.some(f => ( f.name.includes('/max') || f.name.includes('/min') ))) {
      if (this.item.fields_mapping.find(f => ( f.name.includes('/avg') ))) {
        return all_mode_items;
      }
      else if (!this.item.fields_mapping.some(f => ( f.name.includes('/avg') ))) {
        return [{'text':"All Prediction Scores", 'value':"allStats"}]
      }
      else {
        return []
      }
      
    },
    ensembleMode: {
      get() {
        if (this.ensembleModeItems.length === 0) {
          return null;
        }
        else if (this.ensembleModeItems.length === 1) {
          return this.ensembleModeItems[0].value;
        }
        else {
          return this.mode;
        }
      },
      set(mode) {
        this.mode = mode;
      },
    }
  },

  methods: {

    getThresholdMode(item) {
      const methods = ['DL', 'ada', 'bnb', 'knn', 'lreg', 'rf', 'svc', 'xgb'];
       let arr = [];
        let frequency = {};
        Object.keys(item).forEach(key => {
            const fieldArr = key.split('/');
            if (fieldArr.length > 1) {
              const label = fieldArr[1];
              if (methods.includes(label)) {
                const val = this.conformalPredictorsAssemble(item[key]);
                arr.push(val[0]);
              }
            } 
            
        });
        

        arr.forEach(field => {
            const label = Object.keys(frequency).find(key => key === field);
            if (!label) {
                frequency[field] = 1;
            } else {
                frequency[label] = frequency[label]+1;
            }
        })


        let maxValue = 0;
        let maxKey = null;
        let duplicates = [];

        for (const [key, value] of Object.entries(frequency)) {
            if (value > maxValue) {
                maxValue = value;
                maxKey = key;
            }
        }

        for (const [key, value] of Object.entries(frequency)) {
            if (value === maxValue) {
                duplicates.push(key);
            }
        };

        let mode = '';

        if (maxKey && duplicates.length <= 1) {
            mode = maxKey;
        } else if (maxKey && duplicates.length > 1) {
            if (duplicates.includes('Out of Domain')) {
                mode = 'Out of Domain'
            } else if (duplicates.includes('Inconclusive')) {
                mode = 'Inconclusive'
            } else if (duplicates.includes('Inactive')) {
                mode = 'Inactive';
            } else {
                mode = 'Active'
            }
        } else {
            mode = 'Out of Domain'
        }

        return this.getModeColor(mode);
    },
    conformalPredictorsAssemble(valList) {
      let inactiveVal = valList[0]
      let activeVal = valList[1]

      
      if (inactiveVal < this.threshold && activeVal < this.threshold) return ["Out of Domain", 'gray'];
      else if (inactiveVal > this.threshold && activeVal > this.threshold) return ["Inconclusive", 'amber'];
      else if (inactiveVal > this.threshold && activeVal < this.threshold) return ["Inactive", 'red'];
      else return ["Active", 'green'];
    },

    

    getModeColor(modeLabel) {
      if (modeLabel === 'Out of Domain') {
        return ['Out of Domain', 'gray']
      } else if (modeLabel === 'Inconclusive') {
          return ['Inconclusive', 'amber']
      } else if (modeLabel === 'Inactive') {
          return ['Inactive', 'red']
      } else {
          return ['Active', 'green'] 
      }
    },
    getMetricColor(value) {
      if (value == null || isNaN(value)) return '';

      if (value >= 0.9) return 'green';
      if (value >= 0.8) return 'light-green';
      if (value >= 0.7) return 'lime';
      if (value >= 0.6) return 'yellow';
      if (value >= 0.5) return 'amber';
      if (value >= 0.3) return 'orange';
      return 'red';
    }
  },

};
</script>

<style lang="scss">
.results-header {
  overflow: hidden;
  white-space: nowrap;

}

.column_diff {
  background-color: rgba(200,200,200,.2) !important;
  text-align: center;
}
</style>