<template>
  <v-dialog v-model="dialog" max-width="400px">

    <v-card class="dialog">
      <v-card-title>
        Login
      </v-card-title>
      <form>
      <v-card-text>
        
        <v-text-field v-model="username" label="Username"
        @keyup.enter="onLogin" />
        <v-text-field
          v-model="password"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          label="Password"
          @keyup.enter="onLogin"
          @click:append="show = !show"
        />
        <div v-if="message">{{ message }}</div>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn type="submit" @click="onLogin">
          Log In
        </v-btn>
      </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>
<script>
import md5 from 'md5';
import DialogBase from '@/components/DialogBase';

export default {
  mixins: [DialogBase],

  data: () => ({
    username: null,
    password: null,
    show: false,
  }),

  methods: {
    async onLogin(e) {
      e.preventDefault();
      let res = await this.$store.dispatch('logIn', { username: this.username, password_hash: md5(this.password) });
      if (res) this.message = res;
      else {
        this.dialog = false;
        this.$router.go();
      }
    },
  },
};
</script>
