<template>
  <v-dialog v-model="dialog" max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="buttonTitle"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :style="{ display: visible ? 'flex' : 'none' }"
        :title="title"
        small
      >
        <template v-if="buttonTitle.startsWith('mdi-')">
          <v-icon>
            {{ buttonTitle }}
          </v-icon>
        </template>
        <template v-else>
          {{ buttonTitle }}
        </template>
      </v-btn>
    </template>

    <v-card class="dialog">
      <v-card-title>
        Edit Access Control
      </v-card-title>
      <v-card-text>

        <v-select v-model="editedItem.access" :items="accessTypes" label="Access" />

        <v-select v-model="editedItem.owner" :items="USERS" label="Owner" />


        <div v-if="message" class="red lighten-4" dense>
          {{ message }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="actionsDisabled" @click="close">
          Cancel
        </v-btn>
        <v-btn :disabled="actionsDisabled" @click="onOk">
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import DialogBase from '@/components/DialogBase';
import AuthBase from '@/components/auth/AuthBase';
import JamlDialogBase from '@/components/JamlDialogBase';

export default {
  mixins: [DialogBase, AuthBase, JamlDialogBase],

  props: {
    items: {
      type: Array,
      required: true
    },
    objectType: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      editedItem: {
        access: 'private',
        owner: null,
        read: [],
        write: [],
      },
      accessTypes: [
        { value: 'authenticated', text: 'Authenticated' },
        { value: 'private', text: 'Private' },
      ],
    };
  },

  computed: {
    ...mapGetters(['USERS']),
  },

  watch: {
    dialog(val) {
      this.message = null;
      if (val) {
        // Since there are multiple methods in 'items', there might be different current access settings
        // we only use one dialog to change them all so
        // I'm grabbing the first method's current acl to show as default
        if (this.items) this.editedItem = Object.assign({}, this.items[0].acl);
      }
    },
  },

  methods: {
    onOk() {

      for (let item of this.items) {

        if (this.objectType == 'model') {
        this.$axios
          .put(`/models/${item.name}/acl`, this.editedItem)
          .then(() => {
            item.acl = Object.assign({}, this.editedItem);
            this.$emit('update:item', item);
            this.dialog = false;
          })
          .catch(error => this.message = error.response ? error.response.data.detail : 'Unknown error'); 
        }
        else if (this.objectType == 'method') {
          this.$axios
            .put(`/models/${item.name}/${item._id.$oid}/acl`, this.editedItem)
            .then(() => {
              item.acl = Object.assign({}, this.editedItem);
              this.$emit('update:item', item);
              this.dialog = false;
            })
            .catch(error => (this.message = error.response ? error.response.data.detail : 'Unknown error'));
        }
        else {      
          this.$axios
            .put(`${this.objectType}/${item._id.$oid}/acl`, this.editedItem)
            .then(() => {
              item.acl = Object.assign({}, this.editedItem);
              this.$emit('update:item', item);
              this.dialog = false;
            })
            .catch(error => (this.message = error.response ? error.response.data.detail : 'Unknown error'));
        }

      }

    },
  },
};
</script>
