<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="buttonTitle"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :style="{ display: visible ? 'flex' : 'none' }"
        :title="title"
        small
      >
        <template v-if="buttonTitle.startsWith('mdi-')">
          <v-icon>
            {{ buttonTitle }}
          </v-icon>
        </template>
        <template v-else>
          {{ buttonTitle }}
        </template>
      </v-btn>
    </template>

    <v-card class="readacross dialog">
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>

        <div style="flex-direction: row; margin-bottom:20px;">
          <v-img 
                contain
                max-height="300px"
                :src="datasetImage" 
                alt=""
              />
          <div style="flex-direction:column; flex: 1 1 auto;">
            <v-text-field 
                  v-model="resultName" 
                  label="Name to save result as" 
                  required
                />
                <div style="flex-direction: row;">
                  <v-text-field 
                    v-model.number="AnalogNum" 
                    type="number" 
                    label="Number of Nearest Neighbors" 
                    hint="Options: 1-19."
                    persistent-hint
                    required
                  />
                  <v-combobox
                  v-model="selectedDesc"
                  :items="selectedDescriptors"
                  :return-object="false"
                  :disabled="selectedDescriptors.length < 2"
                  label="Select Neighbors By"
                  style="flex:1 1 auto;"
                  required
                ></v-combobox>
              </div>
              <v-combobox
                v-model="filterByDefault"
                :items="filterBy"
                :return-object="false"
                label="Filter By"
                required
              ></v-combobox>
          </div>
        </div>
      </v-card-text>
      <v-card-subtitle style="font-weight: bold;">Select Read-Across Dataset</v-card-subtitle>
      <v-card-text>
        <div style="flex-direction: row; align-items: start;">

          <v-radio-group
            v-model="datasetType"
            row
            style="flex:0 0 auto;"
          >
            <v-radio
              label="ToxRef"
              value="toxref"
            ></v-radio>
            <v-radio
              label="AssayCentral"
              value="ac"
            ></v-radio>
          </v-radio-group>

          <v-select
            v-model="selectedDataset"
            :item-text="dsItemText"
            :item-value="dsItemValue"
            :items="dsItems"
            :disabled="datasetType === 'toxref' || !filterByDefault"
            chips
            deletable-chips
            dense
            label="Select an Assay Central dataset to use for read-across"
            multiple
            hint="Only datasets matching the filter by label will be shown."
            persistent-hint
            style="flex: 1 1 auto;"
          />
        </div>
        
        <b-alert
            :show="dismissCountDown"
            :variant="alertVariant"
            @dismissed="closeAlert()"
            @dismiss-count-down="countDownChanged"
          >
            {{ message }}
          </b-alert>

          <v-progress-linear v-if="uploading" indeterminate />
      </v-card-text>

      <v-card-actions style="flex-direction: row; justify-content: end;">
        <v-btn @click="dialog = false"> Cancel </v-btn>
        <v-btn :disabled="actionsDisabled" @click="onOk"> Read-Across </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { API_URL } from '@/main';
import JamlDialogBase from '@/components/JamlDialogBase';
import { replaceSpecChars } from '@/main';

export default {
  mixins: [JamlDialogBase],
  props: {
    molID: {
      type: String,
      default: 'not read',
    },
  },
  data: () => ({
    resultName: '',
    datasetType: 'toxref',
    selectedDataset: [],
    allDatasets: [],
    dialog: false,
    id: null,
    selectedDescriptors: [
      { text: 'ECFP', value: 'ECFP' },
      { text: 'MACCS', value: 'MACCS' },
    ],
    selectedDesc: '',
    filterBy: [
      { text: 'Classification', value: 'Classification' },
      { text: 'Regression', value: 'Regression' },
    ],
    filterByDefault: '',
    AnalogNum: 10,
    successUrl_: null,
    result: [],
  }),

  computed: {
    formTitle() {
      return 'Read Across';
    },
    datasetImage() {
      return `${API_URL}render/${this.molID}`;
    },
    successUrl() {
      return this.successUrl_;
    },
    dsItems() {
      if (this.filterByDefault === "Classification") {
        return this.allDatasets.filter(ds => ds.fields_mapping.find(label => label.type === 'single-class-label'));
      }
      else if (this.filterByDefault === "Regression") {
        return this.allDatasets.filter(ds => ds.fields_mapping.find(label => label.type === 'continuous-value'));
      }
      else return [];
    },
    isToxref() {
      if (this.datasetType === 'toxref') return true;
      else return false;
    }
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$axios.get('datasets').then((response) => {
          this.allDatasets = response.data;
        });
      }
    }
  },
  methods: {
    dsItemText(mdl) {
      return mdl.name;
    },
    dsItemValue(mdl) {
      return mdl._id.$oid;
    },
    onOk() {
      this.uploading = true;
      this.actionsDisabled = true;
      this.$axios
        .post('readacross', {
          result_name: replaceSpecChars(this.resultName),
          mol_id: this.molID,
          desc: this.selectedDesc,
          filter: this.filterByDefault,
          num_neighbors: this.AnalogNum,
          dataset_for_ra: this.selectedDataset,
          toxref: this.isToxref,
          smiles: "False",
        })
        .then((response) => {
          this.onOkSuccess();
          this.result = response.data;
          this.successUrl_ = `/readacross/${this.result._id.$oid}`;
          this.message = 'Molecule submitted for Read-Across';
        })
        .catch((error) => this.onOkError(error));
    },
  },
};
</script>