<template>
  <v-app>

    <div class="header-flex">
      <v-app-bar  
        :color="CONFIG.barcolor" 
        dark
      >

        <div class="branding">
          <router-link to="/">
            <v-img :src="CONFIG.logo" contain transition="scale-transition" width="40" class="shrink mr-2"  />
          </router-link>
          {{ CONFIG.title }}
        </div>

        <v-spacer />
        <div class="header-links">
          <v-btn class="ma-1 no-link-under" outlined to="/files">Files</v-btn>
          <v-btn class="ma-1 no-link-under" outlined to="/datasets">Datasets</v-btn>
          <v-btn class="ma-1 no-link-under" outlined to="/models">Models</v-btn>
          <v-btn class="ma-1 no-link-under" outlined to="/predict">Predict</v-btn>
          <v-btn class="ma-1 no-link-under" outlined to="/resultsets">Resultsets</v-btn>
          <v-btn v-if="CONFIG.readacross=='true'" class="ma-1 no-link-under" outlined to="/readacross">ReadAcross</v-btn>
          <v-btn v-if="feature('jobs-view') && (canPredict || canTrain)" class="ma-1 no-link-under" outlined to="/jobs"
            >Jobs
          </v-btn>
          <v-btn v-if="feature('users-view') && isAdmin" class="ma-1 no-link-under" outlined to="/users">Users</v-btn>
          <v-btn v-if="!isAuthenticated" class="ma-1 no-link-under" outlined @click="showLogin = true">Log In</v-btn>
          <v-btn v-else class="ma-1 no-link-under" outlined @click="logout">Log Out</v-btn>
          <LoginDialog v-model="showLogin" />
        </div>
      </v-app-bar>
    </div>

    <div class="main-flex">
      <v-main>

          <router-view />
          <!-- This is where the components are placed -->

      </v-main>
    </div>

    <div class="footer-flex">
      <v-footer 
        :color="CONFIG.barcolor" 
        dark 
        padless
      >
      <div class="footer-links">
        <v-btn
          v-for="link in CONFIG.links"
          :key="link.icon"
          :href="link.url"
          :title="link.title"
          icon
          target="_blank"
        >
           <v-icon size="24px">
            {{ link.icon }}
          </v-icon>
        </v-btn>

        <v-btn 
          key="mdi-sync-alert"
          title="Rebuild Model Index"
          icon
          class="ml-10"
          @click="rebuildModelIndex"
        >
          <v-icon v-if="rebuildMe" class="mdi-spin" size="24px">
            mdi-sync-alert
          </v-icon>
          <v-icon v-else>
            mdi-sync-alert
          </v-icon>
        </v-btn>
      </div>
        <div class="copyright" v-html="CONFIG.copyright"></div>
      </v-footer>
    </div>

  </v-app>
</template>

<script>

import { mapGetters } from 'vuex';
import LoginDialog from '@/components/auth/LoginDialog';

export default {

  components: { LoginDialog },

  data: () => ({
    showLogin: false,
    rebuildMe: false
  }),

  beforeCreate() {
    this.$store.dispatch('initMetadata');
  },

  computed: {
    ...mapGetters(['isAuthenticated', 'isAdmin', 'canPredict', 'canTrain', 'feature', 'CONFIG'])
  },

  methods: {
    async logout() {
      await this.$store.dispatch('logOut');
      this.$router.go();
    },
    rebuildModelIndex() {
      this.rebuildMe = true;
      this.$axios
        .post('rebuildmodelindex')
        .then(alert('The model index rebuilt.'))
        .catch(() => (alert('The model index did not rebuild.')));
     this.rebuildMe = false;
      }
  }
};
</script>
