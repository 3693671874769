<template>
    <div style="flex-direction: column; flex: 1 1 auto;">

        <MoleculeDialog
            v-model="isClicked"
            :molecule-id="moleculeID"
            :molecule-name="moleculeName"
            persistent="true"
        />

        <Plotly 
        ref="crazyPlotly"
        :data="predictionValues" 
        :layout="layout_pred"
        :scrollZoom='true'
        :displaylogo='false'
        displayModeBar=true
        responsive=true
        :modeBarButtonsToRemove="[ 'toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian']"
        :toImageButtonOptions="{format: 'svg', scale: 1}"
        ></Plotly>

        <div class="instructions">Click on a bar to get that molecule's details.</div>

    </div>
</template>

<script>
import { Plotly } from 'vue-plotly'
import MoleculeDialog from '@/components/MoleculeDialog'

export default {

    components: { Plotly, MoleculeDialog }, 

    props: {
        item: {
            type: Object,
            required: true
        },
    },

    data() {
        return {
            graphHeight: 100,
            getMolDialog: null,
            moleculeID: '',
            moleculeName: '',
            isClicked: false
        };
    },

    mounted() {
        this.$refs.crazyPlotly.$on('click', d => this.clickMolecule(d));
    },

    computed: {
        layout_pred() {
            let layout_object = {
                xaxis: {
                    title: {
                        text: 'Probability-Like Scores',
                        standoff: 40
                    },
                    zeroline:false
                },
                yaxis: {
                    title: {
                        text: 'Molecule',
                        standoff: 40
                    },
                    showgrid: true,
                    gridwidth: 1,
                    gridcolor: 'rgb(243,243,243)',
                    zeroline: false
                },
                margin: {
                    l: 130,
                    r: 60,
                    t: 60,
                    b: 100,
                    pad: 10
                },
                autosize: true,
                showlegend: false,
                font: {
                    color: '#333333',
                    family: 'Trebuchet MS, Tahoma, Arial'
                },
                dragmode: 'pan',
                modebar: {
                    color: '#555555',
                    activecolor: '#315FB5',
                    orientation: 'h'
                },
                hovermode: "closest",
                hoverlabel: { bgcolor: "#FFF" },
            }

            layout_object['title'] = this.item.name + " Prediction"

            layout_object['height'] = this.graphHeight

            return layout_object
        },

        predictionValues() {

            function median(scores) {
                scores.sort()
                let midpoint = scores[Math.floor(scores.length/2)]
                if (scores.length % 2 === 1) {
                    return midpoint
                }
                else {
                    let lower_midpoint = scores[Math.floor(scores.length/2)-1]
                    return ( (midpoint + lower_midpoint) / 2 )
                }
            }

            let vals = []
            let method_list = []
            let molecule_name = null
            let molecule_id = null

            for (let field of this.item.fields_mapping) {
                if (field.type == 'predicted-value') {
                    method_list.push(field.name)
                }
                else if (field.type == 'chem-id') {
                    molecule_id = field.name
                }
                else if (field.type == 'chem-name') {
                    molecule_name = field.name
                }
            }

            for (let molecule of this.item.records) {
                let mol_object = {}

                if (molecule.molecule_name != null) {
                    mol_object['name'] = molecule.fields[molecule_name]
                }
                else if (molecule.molecule_id != null) {
                    mol_object['name'] = molecule.fields[molecule_id]
                }
                else {
                    mol_object['name'] = 'Molecule' + molecule.fields.ord
                }
                
                mol_object['text'] = molecule.molecule['$oid']
                mol_object['type'] = 'box'
                mol_object['boxmean'] = true

                mol_object['hoverinfo'] = 'x'
                mol_object['hovertext'] = ''
                
                let method_preds = []

                for (let meth of method_list) {
                    if (!meth.includes('avg')) {
                        method_preds.push(molecule.fields[meth])
                    }
                }

                let med = median(method_preds)
                mol_object['sort_by'] = med

                let color_pallette = ['rgb(94,123,54)','rgb(148,186,51)','rgb(255,215,45)','rgb(249,160,41)','rgb(219,94,43)','rgb(216,29,56)']
                if (med > 0.9) {
                    mol_object['marker'] = {
                        color: color_pallette[0],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else if (med > 0.8) {
                    mol_object['marker'] = {
                        color: color_pallette[1],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else if (med > 0.7) {
                    mol_object['marker'] = {
                        color: color_pallette[2],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else if (med > 0.6) {
                    mol_object['marker'] = {
                        color: color_pallette[3],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else if (med > 0.5) {
                    mol_object['marker'] = {
                        color: color_pallette[4],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else {
                    mol_object['marker'] = {
                        color: color_pallette[5],
                        size: 3,
                        opacity: 0.8
                    }          
                }
                mol_object['x'] = method_preds

                vals.push(mol_object)          
            }

            vals.sort((a, b) => (a.sort_by > b.sort_by) ? 1 : -1)
            return vals
        }
    },

    methods: { 
        clickMolecule(d) {
            this.moleculeID = d.points[0].fullData.text;
            this.moleculeName = d.points[0].fullData.name;
            this.isClicked = true;
        } 
    }
}

</script>