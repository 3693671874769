<template>
    <v-dialog :value.sync="value" max-width="800">
  
      <v-card class="dialog">
  
        <v-card-title style="margin-bottom:0;">
            Molecule Details
        </v-card-title>
  
        <v-card-text style="padding-bottom:0;">
            Reference Row in the Resultset: {{ moleculeName }}
            <br />
            Inchi Key: {{ moleculeDetails.inchi_key }}
            <br />
            <img :id="moleculeId" :src="`${API_URL}render/${moleculeId}`" class="boxplot-structure" alt="molecular structure" />
          
          <b-alert
            :show="dismissCountDown"
            :variant="alertVariant"
            @dismissed="closeAlert()"
            @dismiss-count-down="countDownChanged"
          >
            {{ message }}
          </b-alert>
  
        </v-card-text>
  
        <v-card-actions style="justify-content: end;">
          <v-btn :disabled="actionsDisabled" @click="close"> Close </v-btn>
        </v-card-actions>
  
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import JamlDialogBase from '@/components/JamlDialogBase';
  import { API_URL } from '@/main';

  export default {
    mixins: [JamlDialogBase],
    props: {
      value: {
        type: Boolean,
        required: false
      },
      moleculeId: {
        type: String,
        required: true,
      },
      moleculeName: {
        type: String,
        required: false
      }
    },

    data: () => ({
        moleculeDetails: {}
    }),
    computed: {
        API_URL() {
            return API_URL;
        }
    },
    watch: {
        async moleculeId() {
            if (this.moleculeId) {
                await this.$axios
                    .get(`molecule/${this.moleculeId}`)
                    .then(response => (this.moleculeDetails = response.data));
            }
        },
    }
  };
  </script>