import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Vue2Filters from 'vue2-filters';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Axios from 'axios';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vue2Filters);

Vue.config.productionTip = false; 

export const API_URL = process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '/api/'; // this is always going to the fallback. it's coming back undefined

Vue.prototype.$axios = Axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

if (localStorage.xAuth) Vue.prototype.$axios.defaults.headers.common['X-Auth'] = localStorage.xAuth;

export const dateFromObjectId = function(objectId) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

export const formatBytes = function(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export function download(filename, data) {
  let opt;
  const format = filename.substr(filename.lastIndexOf('.') + 1).toLowerCase();
  if (format === 'xlsx') opt = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
  else if (format === 'csv') opt = { type: 'text/csv' };
  else if (format === 'sdf') opt = { type: 'chemical/x-mdl-sdfile' };
  else if (format === 'zip') opt = { type: 'application/zip' };
  else opt = { type: 'text/plain' };

  let blob = new Blob([data], opt); // pass a useful mime type here
  let url = URL.createObjectURL(blob);

  let element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function getDatedFilename(prefix, ext) {
  const now = new Date();
  return prefix + '-' + now.toISOString().substring(0, 19) + '.' + ext;
}

export function getActivityColor(value) {
  switch (parseInt(value)) {
    case 0:
      return 'red lighten-3';
    case 1:
      return 'green';
  }
}

export function roundup(value, digits) {
  if (!isNaN(value)) {
    const round = Math.round(Math.pow(10, digits));
    return Math.round((parseFloat(value) + Number.EPSILON) * round) / round;
  }
}

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function replaceSpecChars(name) {
  return name.replaceAll('?', '_').replaceAll('/','_').replaceAll('%', '_percent_').replaceAll('#', '_').replaceAll('.', '_');
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
