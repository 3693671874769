<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['stateUser', 'stateSession', 'isAdmin', 'isAuthenticated']),
    canCreate() {
      return this.hasPrivilege('create');
    },
    canTrain() {
      return this.hasPrivilege('train');
    },
    canPredict() {
      return this.hasPrivilege('predict');
    },
  },
  methods: {
    canDelete(item) {
      return this.isAdmin || this.isOwner(item);
    },
    canACL(item) {
      return this.isAdmin || this.isOwner(item);
    },
    // isAdmin() {
    //   return this.isAdmin;
    // },
    canRead(item) {
      let readIt = item || this.item;
      if (!readIt) return false;
      if (!readIt.acl) return true;
      return (
        this.isAdmin ||
        this.isOwner(readIt) ||
        readIt.acl.access === 'public' ||
        (this.isAuthenticated && readIt.acl.read.includes(this.stateUser._id.$oid))
      );
    },
    canWrite(item) {
      let writeIt = item || this.item;
      if (!writeIt || !writeIt.acl) return false;
      return (
        this.isAdmin || this.isOwner(writeIt) || (this.isAuthenticated && writeIt.acl.write.includes(this.stateUser._id.$oid))
      );
    },
    isOwner(item) {
      let bah = item || this.item;
      if (!bah) return false;
      return this.isAuthenticated && (!bah.acl || bah.acl.owner === this.stateUser._id.$oid);
      // return this.isAuthenticated && it.acl.owner === this.stateUser._id.$oid;
      // return true;
    },
    hasPrivilege(priv) {
      return this.isAuthenticated && (this.isAdmin || this.stateSession.privileges.includes(priv));
    },
  },
};
</script>
