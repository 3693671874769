<template>
  <v-card class="predict content">

    <v-card-text>
      <div style="flex-direction: row; flex: 0 1 auto; align-items: center;">
        Look up molecule by
        <v-radio-group 
          v-model="searchType"
          row
        >
          <v-radio label="Name" value="name"></v-radio>
          <v-radio label="CAS Number" value="cas"></v-radio>
          <v-radio label="SMILES" value="smiles"></v-radio>
        </v-radio-group>
      </div>
      
      <!-- Autocomplete lookup dialog -->
      <v-autocomplete
        v-if="searchType === 'name' || searchType === 'cas'"
        v-model="lookup_result"
        :loading="loading"
        :search-input.sync="search"
        :items="items"
        :item-text="searchType"
        item-value="cas"
        class="mb-5"
        append-icon="mdi-magnify"
        hide-no-data
        hide-details
        :label="epaLookUpType"
        solo
      ></v-autocomplete>

      <v-text-field
        v-else
        v-model="smiles"
        class="mb-5"
        append-icon="mdi-magnify"
        hide-no-data
        hide-details
        :label="epaLookUpType"
        solo
      />

      <!-- Molecule Drawing Frame -->
      <Ketcher
        :source="smiles"
      />

      <!-- ReadAcross Dialog -->
      <ReadAcrossDialogKetcher v-model="visibleReadAcross" :smiles="selected_r" />

      <!-- Model options -->
      <v-select
        v-if="availableModels"
        v-model="models"
        :items="modelNames"
        chips
        class="mt-5"
        style="margin-left:0; padding-left:0;"
        deletable-chips
        dense
        hint="Select models to use for prediction"
        label="Models"
        multiple
        persistent-hint
      /> 

    </v-card-text>
    
    <!-- Predict/ReadAcross Buttons and possible error message -->
    <v-card-actions>
      <b-alert :show="!!message" variant="danger">
        {{ message }}
      </b-alert>

      <v-spacer />

      <v-btn v-if="CONFIG.readacross=='true'" :disabled="!smiles" @click="createReadAcross">
        ReadAcross
      </v-btn>

      <v-btn :disabled="!smiles || running || !models || !models.length" @click="onPredict">
        Predict
        <v-icon v-if="running" class="mdi-spin"> mdi-rotate-right </v-icon>
      </v-btn>
    </v-card-actions>


    <!-- Predict Results  -->
    <v-toolbar 
      v-if="result && result.length > 0" 
      class="structure-predict"
    >
      <v-slider
        v-if="result.length > 0 && models.filter(f => f.conf_pred === true).length>0 && toggledAverage == false"
        v-model="threshold"
        class="align-center"
        :max="threshold_max"
        :min="threshold_min"
        :step="threshold_step"
        hide-details
      >
        <template v-slot:append>
          <v-text-field
            v-model="threshold"
            class="mt-0 pt-0"
            hide-details
            single-line
            type="number"
            :max="threshold_max"
            :min="threshold_min"
            :step="threshold_step"
            style="width: 100px;"
          ></v-text-field>
        </template>
      </v-slider>
      <v-switch 
        v-if="result && result.length && hasAvg" v-model="toggledAverage"
        color="#5E92DB"
        label="Avg" 
        hide-details
      />
    </v-toolbar>

    <v-data-table
      v-if="result && result.length"
      :headers="headers"
      :items="predictionResults"
      :options="{itemsPerPage:-1}"
      fixed-header
      hide-default-footer
    >
      <template v-slot:item.value="{ item }">
        <v-chip v-if="Array.isArray(item.value)" :color="conformalPredictorsAssemble(item.value)[1]" :small="true">
          {{ conformalPredictorsAssemble(item.value)[0] }}
        </v-chip>
        <v-chip v-else :color="getPredictionColor(item.value)" :small="true">
          {{ roundup(item.value, 2) }}
        </v-chip>
      </template>
    </v-data-table>

</v-card>

</template>

<script>
import Ketcher from '@/components/lookup/Ketcher';
import ReadAcrossDialogKetcher from '@/components/ReadAcrossDialogKetcher';
import { roundup } from '@/main';
import { mapGetters } from 'vuex';
import Axios from 'axios';

export default {
  components: { Ketcher, ReadAcrossDialogKetcher },

  data: () => ({
    visibleReadAcross: false,
    smiles: null,
    result: [],
    message: null,
    running: false,
    availableModels: [],
    models: null,
    headers: [
      { text: 'Model', value: 'model' },
      { text: 'Method', value: 'method' },
      { text: 'Predicted value', value: 'value' },
    ],
    selected_r: null,
    loading: false,
    search: null,
    searchType: 'name',
    epaLookUpType:"Search for a molecule using its name.",
    lookup_result: '',
    textSMILES: null,
    items:[],
    toggledAverage: true,
    threshold: 0.75,
    threshold_max: 0.95,
    threshold_min: 0.05,
    threshold_step: 0.05,
  }),

  computed: {
    ...mapGetters(['CONFIG']),
    modelNames() {
      return Object.keys(this.availableModels);
    },    
    model_ids() {
      let ids_list = []
      if (this.models) {
        for (let model of this.models) {
          ids_list = ids_list.concat(Object.values(this.availableModels[model]['method_ids']));
        }
        return ids_list;
      }
      else {
        return [];
      }
    },
    hasAvg() {
      if (this.result.filter(f => f.method.includes('avg')).length > 0) {
        return true;
      }
      else return false;
    },
    average() {
      if (this.hasAvg && this.toggledAverage) {
        return true;
      }
      else return false;
    },
    predictionResults() {
      return [].concat(this.result.filter(f => f.method.includes('avg') && this.average)).concat(this.result.filter(f => !f.method.includes('avg') && !this.average))
    }
  },
  watch: {
    searchType(type) {
      if (type === 'name') this.epaLookUpType = "Search for a molecule using its name.";
      else if (type === 'cas') this.epaLookUpType = "Search for a molecule using its CAS number.";
      else this.epaLookUpType = "Search for a molecule using SMILES.";
    },
    async search (query) {
      this.loading = true;

      if (this.searchType === 'name') {
        let result = await Axios.get('https://cdxappsdev.epacdx.net/oms-substance-registry-services/rest-api/autoComplete/nameSearch?qualifier=contains&term=' + encodeURIComponent(query));
        this.items = result.data; 
      }
      else if (this.searchType === 'cas') {
        let result = await Axios.get('https://cdxappsdev.epacdx.net/oms-substance-registry-services/rest-api/autoComplete/casSearch' + encodeURIComponent(query));
        this.items = result.data; 
      }
      this.loading = false;
    },
    async lookup_result (item) {
      let get_smiles = {};
      get_smiles = await Axios.get('https://cactus.nci.nih.gov/chemical/structure/' + item.replace(/"/g, '') + '/smiles');
      this.smiles = get_smiles.data;
    },
  },
  methods: {

    roundup(value, digits) {
      return roundup(value, digits);
    },
    createReadAcross() {
      this.selected_r = this.smiles;
      this.visibleReadAcross = true;
    },
    // isBinaryValue(item) {
    //   if (item.method !== 'avg') return !item.method.endsWith('r');

    //   let hh = this.result.find((r) => r.model === item.model && r.method !== 'avg');
    //   return !hh.method.endsWith('r');
    // },
    conformalPredictorsAssemble(valList) {
      let inactiveVal = valList[0]
      let activeVal = valList[1]
      
      if (inactiveVal < this.threshold && activeVal < this.threshold) return ["Out of Domain", 'gray'];
      else if (inactiveVal > this.threshold && activeVal > this.threshold) return ["Inconclusive", 'amber'];
      else if (inactiveVal > this.threshold && activeVal < this.threshold) return ["Inactive", 'red'];
      else return ["Active", 'green'];
    },
    getPredictionColor(value) {
      if (value == null || isNaN(value)) return '';

      if (value >= 0.9) return 'green';
      if (value >= 0.8) return 'light-green';
      if (value >= 0.7) return 'lime';
      if (value >= 0.6) return 'yellow';
      if (value >= 0.5) return 'amber';
      if (value >= 0.3) return 'orange';
      return 'red';
    },
    onPredict() {
      this.running = true;
      this.message = null;
      this.$axios
        .post('predict', {
          structures: [this.smiles],
          model_ids: this.model_ids,
          models: this.models,
          average_mode: this.toggledAverage
        })
        .then(response => {
          this.running = false;
          this.message = null;
          this.result = response.data;
        })
        .catch(error => {
          this.running = false;
          this.message = error.response ? error.response.data.detail : 'Unknown error';
        });
    },
  },

  mounted() {
    this.$axios.get('models').then((response) => {
      this.availableModels = response.data;
    });
  },
};
</script>
