<script>
import { API_URL, download, roundup } from '@/main';
import AuthBase from '@/components/auth/AuthBase';
import { mapGetters } from 'vuex';

export default {
  mixins: [AuthBase],

  data: () => ({
    item: {
      dataset: {
        files: [],
      },
      nn_molecules:[],
      descriptors: [],
      fields: [],
      fields_mapping: [],
      files: [],  
      metadata: {},
      method_name: '',
      metrics: [],
      name: null,
      records: [],
      protocol:[]
    },
    search: null,
    downloading: false,
    view: 0,
  }),

  computed: {
    ...mapGetters(['feature', 'CONFIG']),
    API_URL() {
      return API_URL;
    },
    AUTO_REFRESH() {
      return null;
    },
    OBJECTS_TYPE() {
      return null;
    },
    headersFields() {
      return this.item.fields
        ? [{ text: 'Structure', value: 'image', sortable: false }].concat(
            this.item.fields.map((f) => ({ text: f, value: f }))
          )
        : [];
    },
    headersNames() {
      return this.item
        ? [{ text: 'Structure', value: 'image', sortable: false }].concat(
            this.item.fields_mapping.filter((f) => f.type).map((f) => ({ text: f.name, value: f.type }))
          )
        : [];
    },
    headersTypes: function () {
      if (this.CONFIG.readacross=='true') {
        return this.item
          ? [
              { text: 'Structure', value: 'image', sortable: false },
              { text: 'Read Across', value: 'read_across', sortable: false, align: 'left'},
            ].concat(this.item.fields_mapping.filter((f) => f.type).map((f) => ({ text: f.name, value: f.type })))
          : [];
      }
      else {
        return this.item
          ? [
              { text: 'Structure', value: 'image', sortable: false },
            ].concat(this.item.fields_mapping.filter((f) => f.type).map((f) => ({ text: f.name, value: f.type })))
          : [];
      }
    },
    items() {
      return this.item
        ? this.item.records.map((r) => {
            if (r.molecule) r.fields.id = r.molecule.$oid;
            r.fields.ord = r.ord;
            return r.fields;
          })
        : [];
    },
  },

  methods: {
    roundup(value, digits) {
      return roundup(value, digits);
    },
    downloadFile(filename) {
      this.downloading = true;

      this.$axios
        .get(`${this.OBJECTS_TYPE}/${this.item._id.$oid}/download`, { responseType: 'blob' })
        .then((response) => {
          this.downloading = false;
          download(filename && typeof filename === 'string' ? filename : this.item.name, response.data);
        })
        .catch(() => (this.downloading = false));
    },
    downloadItem(filename, format) {
      this.downloading = true;
      this.ext = format;
      const fmt = format ? `?format=${format}` : '';
      this.$axios
        .get(`${this.OBJECTS_TYPE}/${this.item._id.$oid}/download${fmt}`, { responseType: 'blob' })
        .then((response) => {
          this.downloading = false;
          download(filename && typeof filename === 'string' ? filename : this.item.name, response.data);
        })
        .catch(() => (this.downloading = false));
    },
    postProcessData() {
      //
    },
    getData() {
      // MethodView.vue
      if (this.$route.params.name && this.$route.params.id) {
        this.$axios
          .get(`models/${this.$route.params.name}/${this.$route.params.id}`)
          .then((response) => {
            this.postProcessData(response.data);
            this.item = response.data;
          });
      }
      else {
        this.$axios
          .get(`${this.OBJECTS_TYPE}/${this.$route.params.id}`)
          .then((response) => {
            this.postProcessData(response.data);
            this.item = response.data;
          });
      }
    }
  },

  watch: {
    view(val) {
      if (val == 0 || val == 1 || val == 2) this.$router.push(`${this.$route.path}?view=${val}`);
    },
  },

  mounted() {
    if (!this.canRead()) this.$router.push('/');
    this.getData();
    if (this.AUTO_REFRESH) this.timer = setInterval(this.getData, this.AUTO_REFRESH);
  },

  beforeDestroy() {
    if (this.timer != null) clearInterval(this.timer);
  },
};
</script>
