<template>
  <div class="predict-iframe">
    <div style="flex-direction: column; flex: 1 1 auto;">
      <!-- Draw Molecule -->
      <iframe ref="ketcherFrame" class="ketcher-frame" src="ketcher/ketcher.html" scrolling="auto" @load="loadFrame"></iframe>
    
      <v-card style="padding:10px; margin-top:5px; margin-bottom:10px;" v-if="this.result != null">
        The above molecule's SMILES string is {{ result }}.
      </v-card>
      
    </div>
  </div>


</template>

<script>
import { mapGetters } from 'vuex';

export default {

  props: {
    source: {
      type: String,
      default: null,
    }
  },

  data() {
    return {
      ketcherInstance: null,
      result: null
    };
  },

  computed: {
    ...mapGetters(['feature']),
    
  },
  watch: {
    source() {
      this.ketcherInstance.setMolecule(this.source);
    }
  },
  methods: {
    loadFrame() {
      this.ketcherInstance = this.$refs.ketcherFrame.contentWindow.ketcher;

      // if (this.value) this.setMolecule(this.value);

      this.ketcherInstance.editor.on('change', this.onStructureChanged);
    },
    onStructureChanged() {
      let self = this;
      this.result = self.ketcherInstance.getSmiles();
    },

    getMol() {
      return this.ketcherInstance.getMolfile();
    },
    setMolecule(mol) {
      this.ketcherInstance.setMolecule(mol);
    }
  },
};
</script>