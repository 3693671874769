<template>
  <v-dialog v-model="dialog" max-width="800">

    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="buttonTitle"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :style="{ display: visible ? 'flex' : 'none' }"
        :title="title"
        small
      >
        <template v-if="buttonTitle.startsWith('mdi-')">
          <v-icon>
            {{ buttonTitle }}
          </v-icon>
        </template>
        <template v-else>
          {{ buttonTitle }}
        </template>
      </v-btn>
    </template>

    <v-card class="dialog">

      <v-card-title>
          {{ formTitle }}
        
      </v-card-title>

      <v-card-text>

        <v-text-field v-model="name" label="Name"></v-text-field>
        
        <v-select v-if="stdizers.length > 1" v-model="stdizer" :items="stdizers" label="Standardizer" />

                
        <template v-if="canPredict && type === 'resultsets'">

          <v-select
            v-if="averagingModes.length > 1"
            v-model="average_mode"
            :items="averagingModes"
            label="Averaging mode"
            persistent-hint
          />

          <!-- input:  versioned name
                output: method_ids for the selected versioned name -->
          <v-autocomplete
            v-model="selectedModels"
            :item-text="modelsListPretty"
            :item-value="modelsListInfo"
            :items="modelsList"

            chips
            deletable-chips
            dense
            label="Models"
            hint="Select models to use for your prediction"
            persistent-hint
            multiple
          />

          <!-- input: method_ids for the selected versioned names
              output: selected method ids -->
          <v-select
            v-if="average_mode=='individual'"
            label="Methods"
            hint="Choose methods from the selected model(s)"
            persistent-hint
            multiple
            v-model="selectedMethods"
            :items="selectedModelsMethods"
            chips
            deletable-chips
          ></v-select>

        </template>
      
        <div class="subtitle" v-if="type === 'resultsets'">Prediction Data Fields</div>
        
        <div class="subtitle-details" v-if="type === 'resultsets'">
          A single-class activity field may be set but only when validating a classification model.
        </div>

        <div class="field-container">

          <div class="fields" v-for="(m, i) in fieldsMap" :key="i" >
            
            <label dense class="ma-5">{{ m.name }}</label>
            <v-select v-model="m.type" :items="fieldTypes" />

            <template v-if="m.type === 'continuous-value'">
              <v-select
                v-model="m.op"
                :items="[
                  { value: null, text: '' },
                  { value: 'log', text: 'Log' },
                ]"
                label="Op"
              />
            </template>

            <template v-if="m.type === 'split-on-value'">
              <v-select v-model="m.op" :items="ops" label="Op"></v-select>
              <v-text-field v-model="m.value" label="Threshold"></v-text-field>
            </template>
          </div>
        
        </div>
        
        <b-alert
          :show="dismissCountDown"
          :variant="alertVariant"
          @dismissed="closeAlert()"
          @dismiss-count-down="countDownChanged"
        >
          {{ message }}
        </b-alert>

        <v-progress-linear v-if="uploading" indeterminate />
      </v-card-text>

      <v-card-actions>
        <v-checkbox v-model="lastOne" label="Last" />
        <v-spacer></v-spacer>
        <v-btn :disabled="actionsDisabled" @click="close"> Cancel </v-btn>
        <v-btn :disabled="actionsDisabled" @click="onOk"> Create </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import JamlDialogBase from '@/components/JamlDialogBase';
import { replaceSpecChars } from '@/main';

export default {
  mixins: [JamlDialogBase],
  props: {
    files: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ['datasets', 'resultsets'].indexOf(value) !== -1,
    },
  },
  data: () => ({
    name: null,
    models: {},
    selectedModels: [],
    selectedMethods: [],
    method_names: [],
    fieldsMap: [],
    dataset: {},
    stdizer: 'Simple',
    mode: null,
    average_mode: 'name',
    model_names: null,
    successUrl_: null,
    ops: [
      { value: 'lt', text: '<' },
      { value: 'le', text: '<=' },
      { value: 'ge', text: '>=' },
      { value: 'gt', text: '>' },
    ],
  }),
  computed: {
    formTitle() {
      return `Create ${this.type.charAt(0).toUpperCase() + this.type.slice(1)}`;
    },
    successUrl() {
      return this.successUrl_;
    },
    filesNames() {
      return this.files.map((f) => f.name);
    },
    stdizers() {
      return this.feature('stdizers');
    },
    averagingModes() {
      return this.feature('ensemble-averaging-modes');
    },
    fieldTypes() {
      if (this.type == 'datasets') {
        return [{ value: null, text: '' }].concat(this.feature('field-types'))  //.filter((f) => f.value !== 'non-activity'))
      }
      else {
        return [{ value: null, text: '' }].concat(this.feature('field-types').filter((f) => f.value !== 'split-on-value' && f.value !== 'continuous-value'))
      }
    },
    modeItems() {
      let res = [];
      if (this.feature('create-batch')) res.push('batch');
      if (this.feature('create-combine')) res.push('combine');
      return res;
    },
    // modelsList() {
    //   return( Object.keys(this.models) );
    // },
    modelsList() {
      let models_list = []
      for (let key of Object.keys(this.models)) {
        let models_obj = {
          'pretty': key.slice(5,),
          'important': key
        }
        models_list.push(models_obj)
      }
      return( models_list );
    },
    selectedModelsMethods() {
      let methods = [];
      for (let model_name of this.selectedModels) {
        for (let method_name of this.models[model_name]['method_names']) {
          methods.push(method_name);
        }
      }

      return methods;
    },
    model_ids() {
      let method_ids_list = [];

      if (this.type === 'datasets') {
        return [];
      } else {
        if (this.average_mode == 'individual') {
          for (let method_name of this.selectedMethods) {
            let model_name = '';
            model_name = method_name.split(' /')[0]; // need to not have trailing space before slash
            method_ids_list.push(this.models[model_name]['method_ids'][method_name]);
          }
        }
        else {
          for (let model_name of this.selectedModels) {
            method_ids_list = method_ids_list.concat(Object.values(this.models[model_name]['method_ids']));
          }
        }
        
        return method_ids_list;
      }

      
    }
  },
  watch: {
    selectedModels() {
      this.model_names = this.selectedModels;
    },
    dialog(val) {
      if (val) { 
        const file = this.files[0];
        this.name = file.name.substr(0, file.name.lastIndexOf('.'));
        if (this.type == 'resultsets') {
          this.$axios.get('models').then(response => (this.models = response.data));
          this.fieldsMap = file.fields.map((f) => ({ name: f, type: null, op: null, value: null }));

        }
        else {
          this.fieldsMap = file.fields.map((f) => ({ name: f, type: null, op: null, value: null }));
        }
        
      }
    }
  },

  methods: {
    modelsListPretty(mdl) {
      return mdl.pretty;
    },
    modelsListInfo(mdl) {
      return mdl.important
    },
    onOk() {
      this.uploading = true;
      this.actionsDisabled = true;
      this.$axios
        .post(`${this.type}`, {
          file_ids: this.files.map((f) => f._id.$oid),
          name: replaceSpecChars(this.name),
          mode: this.mode,
          average_mode: this.average_mode,
          model_names: this.model_names,
          stdizer: this.stdizer,
          model_ids: this.model_ids,
          fields_mapping: this.fieldsMap.filter((f) => f.type != null),
        })
        .then((response) => {
          this.onOkSuccess();
          if (this.type === 'datasets') {
            if (response.data.length === 1) {
              this.dataset = response.data[0];
              this.successUrl_ = `/${this.type}/${this.dataset._id.$oid}`;
            } else if (response.data.length > 1) {
              this.dataset = response.data[0];
              this.successUrl_ = `/${this.type}`;
            }
          }
          else {
            this.dataset = response.data[0];
            this.successUrl_ = `/jobs`
          }
        })
        .catch((error) => this.onOkError(error));
    },
  },
};
</script>