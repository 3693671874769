<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="buttonTitle"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :style="{ display: visible ? 'flex' : 'none' }"
        :title="title"
        small
        @click="onButtonActivate"
      >
        <template v-if="buttonTitle.startsWith('mdi-')">
          <v-icon>
            {{ buttonTitle }}
          </v-icon>
        </template>
        <template v-else>
          {{ buttonTitle }}
        </template>
      </v-btn>
    </template>

    <v-card class="dialog">
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>

        <v-text-field  v-if="item != null" v-model="editedItem.name" label="Name" />

        <v-text-field v-model="editedItem.project" label="Project" />

        <v-textarea v-model="editedItem.description" label="Description" />

        <div class="meas-target">
          <v-text-field v-model="editedItem.measurementType" label="Measurement Type (e.g. IC50/EC50/AC50/GI50)" />
          <v-text-field v-model="editedItem.target" label="Target" />
        </div>

        <div class="org-access">
          <v-text-field v-model="editedItem.organism" label="Organism" />
          <v-select v-if="item == null" v-model="access" :items="accessTypes" label="Access" />
          <v-spacer v-else></v-spacer>
        </div>

        <div class="uploads">
          <v-textarea
            v-if="item == null" 
            ref="uploadList"
            v-model="casList"
            hide-details=true
            outlined
            label="Upload a list of compounds (CAS Numbers only)"
            :disabled="usingFiles"
          >
          </v-textarea>

          <div class="file-upload">
            <v-file-input 
              v-if="item == null" 
              ref="uploadFile"
              v-model="uploadedFile"
              :disabled="usingCasList"
              :label="`Upload a ${feature('upload-formats').join(', ')} file.`"
              :rules="[value => {return value != null || 'You forgot to upload a file.'}]"
              hint="All files MUST have column headers to upload properly."
              persistent-hint
              show-size
              required
            />
            <v-select
              v-if="hasCsv"
              v-model="fieldName"
              :disabled="!fieldTypes.length" 
              :items="fieldTypes"
              :rules="[value => !!value || 'Required']"
              label="Structure Field"
              class="ml-8 mt-5"
            ></v-select>
          </div>
        </div>
        <!-- horizontal progress bar -->
        <v-progress-linear v-if="uploading" indeterminate />
      </v-card-text>

      <v-card-actions>
        <b-alert
          :show="dismissCountDown"
          :variant="alertVariant"
          @dismissed="closeAlert()"
          @dismiss-count-down="countDownChanged"
        >
          {{ message }}
        </b-alert>
        <v-spacer></v-spacer>
        <v-btn :disabled="actionsDisabled" @click="close">
          Cancel
        </v-btn>
        <v-btn :disabled="item != null && !canWrite()" @click="onOk">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import JamlDialogBase from '@/components/JamlDialogBase';
import MetadataEditor from '@/components/MetadataEditor';
import { getDatedFilename } from '@/main';

export default {
  mixins: [JamlDialogBase, MetadataEditor], 

  props: {
    onButtonActivate: {
      type: Function,
      default: () => {
      },
    }
  },

  data: () => ({
    fieldName: null,
    fieldTypes: [],
    uploadedFile: null,
    casList: null,
    access: 'private',
    accessTypes: [
      { value: 'authenticated', text: 'Authenticated' },
      { value: 'private', text: 'Private' },
    ],
    successUrl_: null,
    usingFiles: false,
    usingCasList: false,
    hasCsv: false
  }),

  computed: {
    OBJECTS_TYPE() {
      return 'files';
    },
    formTitle() {
      if (this.item) {
        if (this.canWrite()) {
          return 'Edit Metadata'
        }
        else {
          return 'View Metadata'
        }
      }
      else return 'Upload File'
    },    
    // successUrl() {
    //   return this.OBJECTS_TYPE;
    // }
  },

  watch: {
    uploadedFile() {
      if (this.uploadedFile === null) {
        this.usingFiles = false;
      }
      else {
        this.usingFiles = true;
        this.hasCsv = this.uploadedFile.name.toLowerCase().endsWith('.csv') || this.uploadedFile.name.toLowerCase().endsWith('.xlsx');

        if (this.hasCsv) {
          let formData = new FormData();
          formData.append('file', this.uploadedFile, this.uploadedFile.name.replaceAll('?', '_').replaceAll('/','_').replaceAll('%', '_percent_').replaceAll('#', '_'));

          this.$axios
            .post('preload', formData)
            .then(response => {
              this.fieldTypes = response.data;
              this.fieldName = this.fieldTypes.find(f => f.match(/smiles/i));
              if (!this.fieldName) {
                this.fieldName = this.fieldTypes.find(f => f.match(/cas/i));
              }
            })
            .catch(error => this.onOkError(error)); // Structure data could not be processed from the file.
        }
      }
    },
    casList() {
      if (this.casList.length) this.usingCasList = true
      else this.usingCasList = false
    }
  },

  methods: {
    onOk() {
      if (this.casList) {
        this.uploading = true;
        this.actionsDisabled = true;
                
        let formData = new FormData();

        let casListName = getDatedFilename('CAS_List', 'csv')
        let totalString = 'CAS Number\n' + this.casList
        var newFile = new File([totalString], casListName, {type: "text/csv"})
        formData.append('file', newFile, casListName)
        
        formData.append('metadata', JSON.stringify(this.editedItem));
        formData.append('field_name', 'CAS Number');
        formData.append('access', this.access);

        this.$axios
          .post(`${this.OBJECTS_TYPE}`, formData)
          .then(this.onOkSuccess())    
          .catch(error => this.onOkError(error));

      }

      else if (this.uploadedFile) {
      
        this.uploading = true;
        this.actionsDisabled = true;
                
        let formData = new FormData();
        formData.append('file', this.uploadedFile, this.uploadedFile.name.replaceAll('?', '_').replaceAll('/','_').replaceAll('%', '_percent_').replaceAll('#', '_'));
        formData.append('metadata', JSON.stringify(this.editedItem));
        formData.append('field_name', this.fieldName);
        formData.append('access', this.access);

        this.$axios
          .post(`${this.OBJECTS_TYPE}`, formData)
          .then(this.onOkSuccess())    
          .catch(error => this.onOkError(error));
      }

      else if (this.item) this.updateMetadata();
      
      else {
        this.uploading = false;
        this.actionsDisabled = false;
      }

    },
  },
};
</script>
