<template>

  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="buttonTitle"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :style="{ display: visible ? 'flex' : 'none' }"
        :title="title"
        small
      >
        <template v-if="buttonTitle.startsWith('mdi-')">
          <v-icon>
            {{ buttonTitle }}
          </v-icon>
        </template>
        <template v-else>
          {{ buttonTitle }}
        </template>
      </v-btn>
    </template>

    <v-card class="dialog">
      <v-card-title>
        {{ formTitle }}
      </v-card-title>
      <v-card-text>

        <v-container v-if="datasets.length">
          <v-row>
            <v-select
              v-model="datasetsNames"
              :items="datasetsNames"
              chips
              dense
              label="Dataset(s)"
              multiple
              persistent-hint
              readonly
            />
          </v-row>
          <v-row>
            <v-text-field v-model="modelName" :disabled="autoName === true" label="Model Name" />
            <v-checkbox v-if="feature('model-auto-name')" v-model="autoName" label="Auto" />
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="fields"
                :items="labelFields"
                :readonly="labelFields.length === 1"
                chips
                deletable-chips
                label="Label field type"
                multiple
              />
            </v-col>
            <v-col>
              <v-select
                v-model="selectedDescriptors"
                :disabled="descrNames.length < 2"
                :items="descrNames"
                chips
                deletable-chips
                label="Descriptors"
                multiple
                persistent-hint
              />
            </v-col>
          </v-row>
          <v-row v-if="selectedDescriptors.length">
            <template v-for="descr in selectedDescriptors">
              <v-col v-for="param in descrParams(descr)" :key="`${descr}-${param}`">
                <v-combobox
                  v-model="params[descr][param]"
                  :disabled="descrParamValues(descr, param).length < 2"
                  :items="descrParamValues(descr, param)"
                  :label="`${descr} ${param}`"
                ></v-combobox>
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col style="flex-direction:column;">
              <v-select
                v-model="methods"
                :items="availableMethods"
                chips
                deletable-chips
                item-text="text"
                item-value="value"
                label="Methods"
                multiple
              />
              <v-checkbox v-if="labelFields[0] !== 'continuous-value'" v-model="conf_pred" label="Train model with the conformal predictor version of each method" />

            </v-col>
          </v-row>
          <v-row v-if="hasDNN">
            <v-col>
              <v-text-field v-model="DL_hyper_params.dropout" label="Dropout rate (0 .. 1)" />
            </v-col>
            <v-col>
              <v-text-field v-model="DL_hyper_params.beta" label="L2 regularization factor" />

            </v-col>
          </v-row>

        </v-container>

        <b-alert
          :show="dismissCountDown"
          :variant="alertVariant"
          @dismissed="closeAlert()"
          @dismiss-count-down="countDownChanged"
        >
          {{ message }}
        </b-alert>

        <v-row v-for="(r, i) in result" :key="i">
          {{ r.error }}
        </v-row>

        <v-progress-linear v-if="uploading" indeterminate />
      </v-card-text>

      <v-card-actions>
        <v-checkbox v-model="lastOne" class="ml-3" label="Last" />
        <v-spacer></v-spacer>
        <v-btn :disabled="actionsDisabled" @click="close">
          Cancel
        </v-btn>
        <v-btn :disabled="actionsDisabled" @click="onOk">
          Train
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import JamlDialogBase from '@/components/JamlDialogBase';
import _ from 'lodash';
import { replaceSpecChars } from '@/main';

export default {
  mixins: [JamlDialogBase],

  props: {
    datasets: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    autoName: true,
    modelName: null,
    selectedDescriptors: ['ECFP'],
    fields: [],
    params: {
      ECFP: { Radius: '3', Bits: '1024' },
      FCFP: { Radius: '3', Bits: '1024' },
    },
    methods: [],
    DL_hyper_params: {
      dropout: 0.5,
      beta: 0.01,
    },
    test_set_size: 0,
    result: [],
    _availableMethods: [],
    conf_pred: false,
    cp_suffix: null
  }),

  computed: {
    ...mapGetters(['CLS_METHODS', 'REG_METHODS']),
    availableMethods() {
      let result = [];
      if (this.fields.includes('single-class-label')) result.push(...this.CLS_METHODS);
      if (this.fields.includes('continuous-value')) result.push(...this.REG_METHODS);
      return result;
    },
    name: {
      get() {
        return this.autoName
          ? `${this.datasets[0].name}-${this.descrSuffix}${this.conf_pred ? '-CP' : ''}`
          : `${this.modelName}${this.conf_pred ? '-CP' : ''}`;
      },
      set(name) {
        this.modelName = replaceSpecChars(name);
      },
    },
    formTitle() {
      return 'Train Models';
    },
    labelFields() {
      let res = [];
      this.datasets.forEach(d => {
        d.fields_mapping
          .filter(f => ['single-class-label', 'multi-class-label', 'continuous-value'].includes(f.type))
          .map(f => f.type)
          .forEach(f => {
            if (!res.includes(f)) res.push(f);
          });
      });
      return res;
    },
    successUrl() {
      return '/jobs';
    },
    datasetsNames() {
      return this.datasets.map(d => d.name);
    },
    descrNames() {
      return this.feature('descriptors').map(d => d.name);
    },
    descriptors() {
      return this.selectedDescriptors.map(selDesc => ({
        provider: this.feature('descriptors').find(d => d.name === selDesc).provider,
        name: selDesc,
        params: this.params[selDesc],
      }));
    },
    descrSuffix() {
      return this.selectedDescriptors
        .map(selDesc => `${selDesc}${parseInt(this.params[selDesc].Radius) * 2}-${this.params[selDesc].Bits}`)
        .join('-');
    },
    hasDNN() {
      return this.methods.map(m => (typeof m === 'string' ? m : m.value)).includes('DL');
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        if (this.datasets.length === 1) this.name = replaceSpecChars(this.datasets[0].name);
        else this.name = null;

        this.fields = this.labelFields;
        if (!_.isEqual(_.sortBy(this._availableMethods), _.sortBy(this.availableMethods))) {
          this.methods = [...this.availableMethods];
          this._availableMethods = [...this.availableMethods];
        }
      }
    },
  },

  methods: {
    descrParams(descr) {
      return Object.keys(this.feature('descriptors').find(d => d.name === descr).params);
    },
    descrParamValues(descr, param) {
      return this.feature('descriptors').find(d => d.name === descr).params[param];
    },
    onOk() {
      this.uploading = true;
      this.actionsDisabled = true;
      this.$axios
        .post('models', {
          ds_ids: this.datasets.map(d => d._id.$oid),
          name: replaceSpecChars(this.name),
          label_fields: this.fields,
          descriptors: this.descriptors,
          methods: this.methods.map(m => (typeof m === 'string' ? m : m.value)),
          hyper_params: {
            DL: this.DL_hyper_params,
          },
          test_set_size: this.test_set_size,
          conf_pred: this.conf_pred
        })
        .then(response => {
          this.onOkSuccess();
          this.result = response.data;
          this.message = 'Models submitted for training';
        })
        .catch(error => this.onOkError(error));
    },
  },
};
</script>
