<template>
  <v-card>
    <v-toolbar class="mb-3">
      <v-text-field
        v-if="feature('table-search')"
        v-model="search"
        append-icon="mdi-magnify"
        hide-details
        label="Search in any field"
        single-line
      />

      <v-spacer />

      <v-switch v-model="selectable" class="mt-6 mr-5" label="Select" />

      <v-chip
        v-for="s in Object.keys(statuses)"
        :key="s"
        :close="status != null"
        :color="getStatusColor(s)"
        :to="`/jobs?status=${s}`"
        class="mr-4 no-link-under"
        @click:close="$router.push('/jobs')"
      >
        <span :title="s">{{ statuses[s] }}</span>
      </v-chip>

      <v-btn-toggle class="mr-2">
        <v-btn class="mr-3" small title="Download jobs" @click="downloadJobs()">
          <v-icon v-if="downloading" class="mdi-spin">
            mdi-rotate-right
          </v-icon>
          <v-icon v-else>
            mdi-file-excel-outline
          </v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle>
        <HeadersDialog
          v-if="feature('table-headers')"
          :available-headers="availableHeaders"
          :headers.sync="headers"
        />
        <DeleteDialog
          v-if="isAuthenticated"
          v-model="visibleDelete"
          :disabled="!selected.length"
          :items="selected"
          :visible="selectable"
          button-title="mdi-trash-can-outline"
          object-type="jobs"
          title="Delete selected jobs"
          @click="deleteItems(selected)"
          @deleted="removeItems"
        />
        <v-menu offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn v-bind="attrs" v-on="on" small>
              <v-icon>
                mdi-playlist-remove
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in what2delete" :key="item.value" link @click="cleanJobs(item.value)">
              <v-list-item-title v-text="item.title" />
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn-toggle>
    </v-toolbar>
    
    <v-data-table
      v-model="selected"
      :footer-props="{ showFirstLastPage: true, itemsPerPageOptions: [10, 20, 50, -1] }"
      :headers="headers"
      :items="items"
      :items-per-page="50"
      :hide-default-footer="items.length < 1"
      :search="search"
      :show-select="selectable"
      sort-by="method"
      group-by="jobsort"
      item-key="id"
      sort-desc
      fixed-header
    >
      <template v-slot:group.header="{items, isOpen, toggle}">
        <td :colspan="headers.length">
          <v-icon @click="toggle"
            >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
          </v-icon>
          {{ items[0].dataset }}
        </td>
      </template>


      <template #item.job_type="{ item }">
        <v-chip outlined small>
          {{ item.job_type }}
        </v-chip>
      </template>


      <template #item.status="{ item }">
        <v-tooltip v-if="item.status === 'Failed'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              :color="getStatusColor(item.status)"
              :to="`/jobs/${item._id.$oid}`"
              class="no-link-under"
              small
            >
              {{ item.status }}
            </v-chip>
          </template>
          {{ item.error }}
        </v-tooltip>
        <v-chip v-else :color="getStatusColor(item.status)" :to="`/jobs/${item._id.$oid}`" class="no-link-under" small>
          {{ item.status }}
        </v-chip>
      </template>


      <template #item.result="{ item }">

        <div v-if="item.job_type === 'train'" class="wrap-300">
          <b-link
            :to="`/models/${item.params.model_name}`"
            class="wrap-300"
          >
            {{ item.params.model_name }}
          </b-link>
        </div>

        <div v-else-if="item.job_type === 'predict'" class="wrap-300">
          <div v-if="item.params.rs_name">
            <b-link 
              v-if="item.rs_id" 
              :to="`/resultsets/${item.rs_id}`"
              class="wrap-300"
            >
              {{ item.params.rs_name }}
            </b-link>
          </div>
          <div v-else>
            <b-link 
              v-if="item.rs_id" 
              :to="`/resultsets/${item.rs_id}`"
              class="wrap-300"
            >
              Prediction
            </b-link></div>
        </div>
      </template>

      <template #item.method="{ item }">
        <b-link v-if="item.model_id" :to="`/models/${item.params.model_name}/${item.model_id}`">
          {{ item.method }}
        </b-link>
        <span v-else>{{ item.method }}</span>
      </template>

      <template #item.dataset="{ item }">
        <div v-if="item.job_type === 'train'" class="wrap-300">
          <b-link :title="item.params.ds_name" :to="`/datasets/${item.params.ds_id}`" class="wrap-300">
            {{ item.params.ds_name }}
          </b-link>
        </div>
        <div v-if="item.job_type === 'predict'" class="wrap-300">
          <b-link :title="item.params.ds_name" :to="`/files/${item.params.ds_id}`" class="wrap-300">
            {{ item.params.ds_name }}
          </b-link>
        </div>
      </template>

      

      <template v-slot:item.execution_time="{ item }">
        {{ item.stats.execution_time ? roundup(item.stats.execution_time, 1) : '' }}
      </template>

      <template #item.owner="{ item }">
        {{ item.acl ? getUser(item.acl.owner) : '' }}
      </template>

      <template #item.dateCreated="{ item }">
        <span class="text-no-wrap">
          {{ dateTimeFormat.format(item.dateCreated) }}
        </span>
      </template>

      <template #item.actions="{ item }">
        <div class="justify-end mr-0">
          <v-icon v-if="canDelete(item)" small title="Delete" @click="deleteItems([item])">
            mdi-trash-can-outline
          </v-icon>
        </div>
      </template>
    </v-data-table>

  </v-card>
</template>

<script>
import TableBase from '@/components/TableBase';

import HeadersDialog from '@/components/HeadersDialog';
import DeleteDialog from '@/components/DeleteDialog';
import { download, getDatedFilename } from '@/main';
import { dateFromObjectId } from '@/main';

export default {
  components: { DeleteDialog, HeadersDialog },

  mixins: [TableBase],

  data: () => ({
    statuses: [],
    allHeaders: [
      { text: 'Type', value: 'job_type' },
      { text: 'Status', value: 'status', align: 'center', fixed: true },
      { text: 'Server', value: 'server_name', initial: false },
      { text: 'Result', value: 'result' },
      { text: 'Dataset', value: 'dataset' },
      { text: 'Method', value: 'method' },
      { text: 'Time, s', value: 'execution_time', align: 'end' },
      { text: 'Owner', value: 'owner', initial: false, authenticated: true },
      { text: 'Created', value: 'dateCreated', initial: false },
      { text: 'Container', value: 'container_name' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'end', groupable: false, fixed: true },
    ],
    what2delete: [
      { value: 'old-1', title: 'Older than 1 hour' },
      { value: 'old-12', title: 'Older than 12 hours' },
      { value: 'old-24', title: 'Older than 1 day' },
      { value: 'old-72', title: 'Older than 3 days' },
      { value: 'Failed', title: 'Failed' },
      { value: 'Done', title: 'Done' },
      { value: 'All', title: 'All' },
    ],
  }),

  mounted() {
    this.getData();
    if (this.AUTO_REFRESH) this.timer = setInterval(this.getData, this.AUTO_REFRESH);
  },

  computed: {
    OBJECTS_TYPE() {
      return 'jobs';
    },
    QUERY() {
      return this.status ? `?status=${this.status}` : '';
    },
    AUTO_REFRESH() {
      return 5000;
    },
    status() {
      return this.$route.query.status;
    },
  },

  watch: {
    items() {
      this.statuses = this.items.reduce((acc, item) => {
        if (item.status in acc) acc[item.status]++;
        else acc[item.status] = 1;
        return acc;
      }, {});
    },
    status() {
      this.getData();
    },
  },

  methods: {
    getStatusColor(status) {
      switch (status) {
        case 'Pending':
        case 'Rescheduled':
          return 'grey lighten-3';
        case 'Running':
          return 'green lighten-4';
        case 'Done':
          return 'green lighten-2';
        case 'Failed':
          return 'red lighten-2';
      }
    },
    postProcessData(s) {
      s.dataset = s.params.ds_name;
      s.model = s.params.model_name;
      s.method = s.params.method;
      // s.name = `${s.model}/${s.method}`;
      if (s.params.rs_ids && s.params.rs_ids[0]) s.rs_id = s.params.rs_ids[0];
      s.id = s._id.$oid;
      if (s._id && s._id.$oid) s.dateCreated = dateFromObjectId(s._id.$oid);
      s.jobsort = -1 * s.dateCreated;
    },
    cleanJobs(what) {
      this.$axios.delete(`${this.OBJECTS_TYPE}?what=${what}`).then(() => this.getData());
    },
    downloadJobs() {
      this.downloading = true;
      this.$axios
        .post(
          `${this.OBJECTS_TYPE}/download`,
          {
            status: this.status,
            ids: [],
          },
          { responseType: 'blob' }
        )
        .then(response => {
          this.downloading = false;
          let filename = getDatedFilename(this.OBJECTS_TYPE, 'xlsx');
          download(filename, response.data);
        })
        .catch(() => (this.downloading = false));
    },
  },
};
</script>
