<template>
  <v-card>

    <v-toolbar>
      <v-text-field
        v-if="feature('table-search')"
        v-model="search"
        append-icon="mdi-magnify" 
        hide-details
        label="Search in any field"
        single-line
      />

      <v-spacer />

      <v-switch v-if="isAuthenticated && isSelectable" v-model="selectable" class="mt-5 mr-4" label="Select" />

      <v-btn-toggle class="mr-2">
        <v-btn
          v-if="isAuthenticated && selectable && feature('download-multi')"
          :disabled="!selected.length"
          class="mr-3"
          small
          title="Download selected files"
          @click="downloadFiles(selected)"
        >
          <v-icon v-if="downloading" class="mdi-spin">
            mdi-rotate-right
          </v-icon>
          <v-icon v-else>
            mdi-download-outline
          </v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-btn-toggle>
      <FileDialog
        v-if="canCreate"
        v-model="visibleFile"
        :disabled="selectable"
        :item.sync="item"
        :on-button-activate="resetItem"
        :visible="!selectable"
        button-title="mdi-upload-outline"
        item-type="files"
        @uploaded="onUploaded"
      />
      <HeadersDialog
        v-if="feature('table-headers')"
        :available-headers="availableHeaders"
        :headers.sync="headers"
      />
      <DeleteDialog
        v-if="isAuthenticated && (!selectable || feature('delete-multi'))"
        v-model="visibleDelete"
        :disabled="!selected.length"
        :items="selected"
        :visible="selectable"
        button-title="mdi-trash-can-outline"
        object-type="files"
        title="Delete selected files"
        @click="deleteItems(selected)"
        @deleted="removeItems"
      />
      <DatasetDialog
        v-if="canCreate && !selectable && CONFIG.title != 'MegaTox'"
        v-model="visibleDataset"
        type="datasets"
        :files="selected"
      />
      <DatasetDialog
        v-if="canCreate && !selectable"
        v-model="visiblePredict"
        :files="selected"
        type="resultsets"
      />
      </v-btn-toggle>
    </v-toolbar>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :items-per-page="50"
      :hide-default-footer="items.length < 10"
      :footer-props="{ showFirstLastPage: true, itemsPerPageOptions: [10, 20, 50, -1] }"
      :search="search"
      :custom-filter="customSearch"
      :show-select="selectable"
      item-key="name"
      sort-by="dateCreated"
      sort-desc
      fixed-header
    >

      <template #item.name="{ item }">
        <b-link :title="item.name" :to="`/${OBJECTS_TYPE}/${item._id.$oid}`" class="wrap-500">{{ item.name }}</b-link>
      </template>

      <template #item.project="{ item }">
        {{ item.metadata.project }}
      </template>
      <template #item.measurementType="{ item }">
        {{ item.metadata.measurementType }}
      </template>
      <template #item.target="{ item }">
        {{ item.metadata.target }}
      </template>
      <template #item.organism="{ item }">
        {{ item.metadata.organism }}
      </template>

      <template #item.size="{ item }">
        <div style="white-space: nowrap;">{{ formatBytes(item.size) }}</div>
      </template>

      <template #item.records_number="{ item }">
        <v-chip> {{ currentRecordNumber(item) }}</v-chip>
      </template>


      <template #item.access="{ item }">
        {{ item.acl.access }}
      </template>
      <template #item.owner="{ item }">
        {{ getUser(item.acl.owner) }}
      </template>
      <template #item.rights="{ item }">
        <div v-if="item.acl.read.length">
          <div>Read:</div>
          <div v-for="(uid, i) in item.acl.read" :key="i">
            {{ getUser(uid) }}
          </div>
        </div>
        <div v-if="item.acl.write.length">
          <div>Write:</div>
          <div v-for="(uid, i) in item.acl.write" :key="i">
            {{ getUser(uid) }}
          </div>
        </div>
      </template>

      <template #item.dateCreated="{ item }">
        {{ dateTimeFormat.format(item.dateCreated) }}
      </template>

      <template #item.fields="{ item }">
        <v-chip v-for="(field, i) in item.fields" :key="`f${i}`" class="ma-1" label outlined small>
          {{ field }}
        </v-chip>
      </template>

      <template v-if="!selectable" #item.actions="{ item }">
        
        <div class="justify-end mr-0">
          <v-icon
            v-if="isAuthenticated"
            class="mr-1"
            small
            title="Create Dataset"
            @click="createDataset([item])"
          >
          mdi-table-plus
          </v-icon>
          <v-icon
          v-if="isAuthenticated"
            class="mr-1"
            small
            title="Create Resultset"
            @click="createResultset([item])"
          >
            mdi-shape-polygon-plus
          </v-icon>
      
          <v-icon 
            v-if="canDelete(item)" 
            class="mr-1"
            small 
            title="Delete" 
            @click="deleteItems([item])"
          >
            mdi-trash-can-outline
          </v-icon>
        </div>
      </template>
    </v-data-table>
    
  </v-card>
</template>

<script>
import { download, getDatedFilename } from '@/main';
import { mapGetters } from 'vuex';
import DeleteDialog from '@/components/DeleteDialog';
import DatasetDialog from '@/components/DatasetDialog';
import FileDialog from '@/components/FileDialog';
import TableBase from '@/components/TableBase';
import HeadersDialog from '@/components/HeadersDialog';

export default {
  components: { HeadersDialog, FileDialog, DatasetDialog, DeleteDialog },

  mixins: [TableBase],

  data: () => ({
    metadata: ['title', 'project', 'description', 'measurementType', 'target', 'organism'],
    allHeaders: [
      { text: 'Name', value: 'name', fixed:true },
      { text: 'Project', value: 'project', initial: true },
      { text: 'Measurement Type', value: 'measurementType', initial: false },
      { text: 'Target', value: 'target', initial: false },
      { text: 'Organism', value: 'organism', initial: false },
      { text: 'Size', value: 'size', align: 'end' },
      { text: 'Records', value: 'records_number', align: 'end' },
      { text: 'Fields', value: 'fields', sortable: false, initial:false },
      { text: 'Access', value: 'access', initial: false, authenticated: true, admin: false },
      { text: 'Owner', value: 'owner', initial: false, authenticated: true, admin: false },
      { text: 'Rights', value: 'rights', sortable: false, initial: false, admin: true },
      { text: 'Created', value: 'dateCreated', initial: false },
      { text: 'Actions', value: 'actions', sortable: false, align: 'end', fixed: true, authenticated: true},
    ],
    visibleFile: false,
    visibleDataset: false,
    visiblePredict: false,
  }),

  computed: {
    OBJECTS_TYPE() {
      return 'files';
    },
    AUTO_REFRESH() {
      return 5000;
    },
    isSelectable() {
      return (
        this.feature('download-multi') ||
        this.feature('delete-multi')
      );
    },
    ...mapGetters(['CONFIG'])
  },

  mounted() {
    // if (!this.canRead()) this.$router.push('/files');
    this.getData();
    if (this.AUTO_REFRESH) this.timer = setInterval(this.getData, this.AUTO_REFRESH);
  },

  beforeDestroy() {
    if (this.timer != null) clearInterval(this.timer);
  },

  methods: {
    currentRecordNumber(item) {
      if (item.records_number) {
        if (item.done === false) return item.records_number + '...'
        else return item.records_number
      }
      else {
        return 'Processing...'
      }
    },
    customSearch(value, search, item) {
      search = search.toLowerCase();
      return Object.values(this.flatItem(item)).some(value => value?.toString()?.toLowerCase()?.includes(search));
    },
    flatItem(item) {
      return {
        name: item.name,
        project: item.metadata.project,
        measurementType: item.metadata.measurementType,
        target: item.metadata.target,
        organism: item.metadata.organism,
        records_number: item.records_number,
        access: item.acl.access
      };
    },
    resetItem() {
      this.item = null;
      this.visibleFile = false;
    },
    onUploaded(files) {
      this.items.push(...files);
    },
    editItem(file) {
      this.item = file;
    },
    createDataset(file) {
      this.selected = file;
      this.visibleDataset = true;
    },
    createResultset(file) {
      this.selected = file;
      this.visiblePredict = true;
    },
    downloadFiles(files) {
      this.downloading = true;
      this.$axios
        .post(`${this.OBJECTS_TYPE}/download`, { ids: files.map(f => f._id.$oid) }, { responseType: 'blob' })
        .then(response => {
          this.downloading = false;
          let filename = getDatedFilename(this.OBJECTS_TYPE, 'zip');
          download(filename, response.data);
        })
        .catch(() => (this.downloading = false));
      },
    },
};
</script>
