<template>
  <v-card>


    <v-toolbar>
      <v-btn class="mr-3" small title="All Datasets" to="/datasets">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      {{ item.name }}
                    
      <span class="ml-3">
          <v-badge :content="statsText" color="blue lighten-2" overlap>
            <v-chip small>{{ item.records_number }}</v-chip>
          </v-badge>
        </span>

      <v-spacer />

      <v-btn-toggle v-if="feature('view-tile')" v-model="view" class="mr-2" dense>
        <v-btn small title="Table View">
          <v-icon>mdi-format-list-checkbox</v-icon>
        </v-btn>
        <v-btn small title="Grid View">
          <v-icon>mdi-view-grid-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="feature('navigate') && item.protocol && item.protocol.$oid"
          small
          title="Protocol View"
          @click="$router.push(`/protocols/${item.protocol.$oid}`)"
        >
          <v-icon>mdi-clipboard-alert-outline</v-icon>
        </v-btn>
      </v-btn-toggle>

      <!-- Go to File -->
      <v-btn-toggle class="mr-2" dense>

        <v-menu v-if="feature('navigate') && item.files.length > 1" offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn v-bind="attrs" v-on="on" small title="Go to file">
              <v-icon>
                mdi-file
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="file in item.files"
              :key="file.$oid"
              
              @click="$router.push(`/files/${file.$oid}`)"
            >
              <v-list-item-title v-text="file.name" />
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          v-if="feature('navigate') && item.files.length == 1"
          class="mr-2"
          small
          title="Go to File"
          @click="$router.push(`/files/${item.files[0].$oid}`)"
        >
            <v-icon style="transform: scaleX(-1);">
              mdi-file-send-outline
            </v-icon>
        </v-btn>

      </v-btn-toggle>
      <!-- End Go to File -->

      <!-- Download Options -->
      <v-btn-toggle class="mr-2" dense>

        <v-btn
          v-if="feature('download-xlsx')"
          small
          title="Export to Excel"
          @click="downloadItem(`${item.name}.xlsx`, 'xlsx')"
        >
          <v-icon v-if="downloading && ext === 'xlsx'" class="mdi-spin">
            mdi-rotate-right
          </v-icon>
          <v-icon v-else>
            mdi-file-excel-outline
          </v-icon>
        </v-btn>
        <v-btn
          v-if="feature('download-sdf')"
          small
          title="Download SDF"
          @click="downloadItem(`${item.name}.sdf`, 'sdf')"
        >
          <v-icon v-if="downloading && ext === 'sdf'" class="mdi-spin">
            mdi-rotate-right
          </v-icon>
          <v-icon v-else>
            mdi-download-outline
          </v-icon>
        </v-btn>
      </v-btn-toggle>
      <!-- End Download Options -->

      <!-- Dialog Icons -->
      <v-btn-toggle dense>
        <FileDialog
                v-if="feature('metadata-edit')"
                :allow-create="false"
                :item.sync="item"
                :title="metadataTitle"
                button-title="mdi-receipt-text-edit-outline"
                item-type="datasets"
              />

        <ModelDialog
          v-if="canTrain && isReadyForModel && CONFIG.title!='MegaTox'"
          :datasets="[item]"
          button-title="mdi-graph-outline mdi-rotate-180"
          title="Train Models"
        />
        <ACLDialog
          v-if="canACL()"
          :items="[item]"
          :object-type="OBJECTS_TYPE"
          button-title="mdi-account-key-outline"
          title="Access Control"
        />
        <DeleteDialog
          v-if="canDelete()"
          :items="[item]"
          :object-type="OBJECTS_TYPE"
          button-title="mdi-trash-can-outline"
          title="Delete"
        />
        <ReadAcrossDialog v-if="CONFIG.readacross=='true'" v-model="visibleReadAcross" :molID="selected_r" />
      </v-btn-toggle>
    </v-toolbar>
    <!-- End Dialog Icons -->


    <v-data-table
      v-model="selected"
      v-if="view !== 1"
      :footer-props="{ showFirstLastPage: true, itemsPerPageOptions: [10, 20, 50, -1] }"
      :headers="headers"
      :items="items"
      :result="null"
      :search="search"
      :show-select="selectable"
      item-key="id"
      fixed-header
    >

      <template #item.Structure="{ item }">
        <img :id="`${item.id}`" :src="`${API_URL}render/${item.id}`" alt="" />
        <chem-popover :record="item" :target="`${item.id}`"></chem-popover>
      </template>
      <template #item.read_across="{ item }">
        <div class>
          <v-icon large class="mr-2" title="Read Across" @click="createReadAcross(item.id)"> mdi-flask-round-bottom </v-icon>
        </div>
      </template>

      <template #item.single-class-label="{ item }">
        <v-chip :color="getActivityColor(getBinaryValue(item))" small />
       </template>
      <template #item.continuous-value="{ item }">
         {{ getContinuousValue(item) }}
      </template> 

    </v-data-table>

    <v-container v-else>
      <records-tile :recordset="item"></records-tile>
    </v-container>
    
  </v-card>
</template>

<script>
import RecordsTile from '@/components/RecordsTile';
import ChemPopover from '@/components/ChemPopover';
import FileDialog from '@/components/FileDialog';
import ModelDialog from '@/components/ModelDialog';
import DeleteDialog from '@/components/DeleteDialog';
import ViewBase from '@/components/ViewBase';
import ACLDialog from '@/components/auth/ACLDialog';
import ReadAcrossDialog from '@/components/ReadAcrossDialog';
// import TanimotoDialog from '@/components/TanimotoDialog';
import { mapGetters } from 'vuex';
import { getActivityColor } from '@/main';
import _ from 'lodash';
export default {
  components: { ACLDialog, RecordsTile, ChemPopover, FileDialog, ModelDialog, DeleteDialog, ReadAcrossDialog }, 

  mixins: [ViewBase],

  data: () => ({
    visibleReadAcross: false,
    visibleTanimoto: false,
    selected: [],
    selectable: false,
    selected_r: null
  }),

  computed: {
    ...mapGetters(['CONFIG']),
    OBJECTS_TYPE() {
      return 'datasets';
    },
    metadataTitle() {
      if (this.canWrite()) {
        return 'View/Edit Metadata'
      }
      else return 'View Metadata'
    },
    headers() {
      return this.headersTypes.map((header) => {
        if (header.value === 'read_across' || header.value === 'continuous-value' || header.value === 'single-class-label')
          return {...header};
        else
          return {...header, value: header.text};}
      );
    },
    isReadyForModel() {
      return this.item.fields_mapping.find((f) =>
        ['single-class-label', 'multi-class-label', 'continuous-value'].includes(f.type)
      );
    },
    statsText() {
      if (!this.item.stats) return '';
      if (this.item.stats.actives) return `${this.item.stats.actives}/${this.item.stats.inactives}`;
      if (this.item.stats.high_value)
        return `${this.roundup(this.item.stats.low_value, 2)}-${this.roundup(this.item.stats.high_value, 2)}`;
      return '';
    },
  },

  methods: {
    getActivityColor(value) {
      return getActivityColor(value);
    },
    createReadAcross(items) {
      this.selected_r = items;
      this.visibleReadAcross = true;
    },
    createTanimoto(items){
      this.selected_t = items;
      this.visibleTanimoto = true;
    },
    getBinaryValue(item) {
      let binaryHeaderText = this.headers.find((header) => header.value === 'single-class-label')?.text;
      if (binaryHeaderText in item) {
        return item[binaryHeaderText];
      }
      else return item['single-class-label'];
    },
    getContinuousValue(item) {
      let continuousHeaderText = this.headers.find((header) => header.value === 'continuous-value')?.text;
      if (continuousHeaderText in item) {
        return _.round(item[continuousHeaderText], 2);
      }
      else return _.round(item['continuous-value'], 2);
    }
  },
};
</script>
